/* --------------------------------
   widget
-------------------------------- */

.widget
{
	position: relative;
	margin-top: 40px;

	&:first-child { margin-top: 0; }

	.clear-filter
	{
		display: inline-block;
		background: none;
		border: none;
		line-height: 1.2;
		font-size: 1.2rem;
		font-weight: 700;
		text-transform: uppercase;
		color: #4f4a37;
	}
}

.widget--text
{
	p
	{
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

.widget--categories
{
	.list
	{
		&__item
		{
			margin-top: 10px;
			line-height: 1.2;
			padding-left: 15px;

			&:before
			{
				content: "";
				float: left;
				width: 0;
				height: 0;
				margin-top: 7px;
				margin-left: -15px;
				margin-right: 10px;
				border: 2px solid #828282;
				border-right-width: 1px;
				border-bottom-width: 1px;
			}

			&:first-child { margin-top: 0; }

			&__link
			{
				display: inline-block;
				color: inherit;

				&:hover,
				&:focus
				{
					color: #888;
				}
			}

			span { float: right; }
		}
	}
}

.widget--search
{

}

.widget--posts
{
	article
	{
		margin-top: 20px;
		line-height: 1.4;

		&:after
		{
			content: "";
			display: table;
			clear: both;
		}

		&:first-child { margin-top: 0; }
	}

	.link
	{
		float: left;
		margin-right: 15px;

		img
		{
			width: 100px;
			height: 75px;
		}

		+ div { overflow: hidden; }
	}

	.date-post
	{
		display: inline-block;
		vertical-align: top;
		margin-top: 5px;
		line-height: 1.2;
		font-size: rem-calc(12px);
		color: #c1c1c1;
	}
}

.widget--tags
{
	ul
	{
		margin: -3px;
		line-height: 0;
		font-size: 0;
		letter-spacing: -1px;
	}

	li
	{
		display: inline-block;
		vertical-align: top;
		margin: 3px;
	}

	a
	{
		display: inline-block;
		padding: 8px 20px;
		background-color: #333;
		line-height: 1.2;
		font-size: rem-calc(11px);
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0;
		color: $white-color;
		@include border-radius(30px);
		box-shadow: none;
		outline: none;
		cursor: pointer;
		user-select: none;
		-webkit-user-drag: none;
		user-drag: none;
		-ms-touch-action: manipulation;
		touch-action: manipulation;

		@include transition(
			background-color 0.25s ease-in-out,
			border-color     0.25s ease-in-out,
			color            0.25s ease-in-out
		);

		&:hover,
		&:focus
		{
			background-color: $primary-color;
			color: #333;
		}
	}
}

.widget--price
{
	.widget-title + div { margin-top: -25px; }

	.range-slider-min-value,
	.range-slider-max-value
	{
		display: block;
		width: 100%;
		margin-top: 5px;
		border: none;
		box-shadow: none;
		outline: none;
		pointer-events: none;
		user-select: none;
	}

	.range-slider-max-value
	{
		text-align: right;
	}
}

.widget--additional
{
	ul { line-height: 1; }

	li
	{
		margin-top: 20px;

		&:first-child { margin-top: 0; }
	}

	.checkfield
	{
		margin-top: 0;
	}
}

.widget--products
{
	ul { line-height: 1; }

	li
	{
		margin-top: 10px;

		&:first-child { margin-top: 0; }
	}

	.__image-wrap
	{
		width: 45%;
		max-width: 120px;
		padding-right: 30px;
	}

	.__image
	{
		position: relative;
		width: 100%;
		height: 0;
		margin: auto;
		padding-top: 100%;
		overflow: hidden;

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			max-width: 100%;
			max-height: 95%;
		}
	}

	.__title
	{
		margin-bottom: 10px;
		line-height: 1.2;
		font-weight: 700;
		text-transform: none;
	}

	.rating
	{
		&__item { font-size: 1.1rem; }
	}

	.product-price
	{
		margin-top: 10px;
		margin-left: -10px;

		&__item { margin-left: 10px; }
	}
}

.widget--banner
{

}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{

}