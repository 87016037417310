@charset "UTF-8";
body.compensate-for-scrollbar {
  overflow: hidden
}
.fancybox-active {
  height: auto
}
.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute!important;
  top: -9999px;
  visibility: hidden
}
.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: 0;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992
}
.fancybox-container * {
  box-sizing: border-box
}
.fancybox-bg,
.fancybox-inner,
.fancybox-outer,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}
.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto
}
.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.47,0,.74,.71)
}
.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(.22,.61,.36,1)
}
.fancybox-caption,
.fancybox-infobar,
.fancybox-navigation .fancybox-button,
.fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s,visibility .25s;
  visibility: hidden;
  z-index: 99997
}
.fancybox-show-caption .fancybox-caption,
.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-nav .fancybox-navigation .fancybox-button,
.fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  transition: opacity .25s,visibility;
  visibility: visible
}
.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.fancybox-toolbar {
  right: 0;
  top: 0
}
.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994
}
.fancybox-is-open .fancybox-stage {
  overflow: hidden
}
.fancybox-slide {
  -webkit-backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform,opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994
}
.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0
}
.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--current,
.fancybox-slide--next,
.fancybox-slide--previous {
  display: block
}
.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0
}
.fancybox-slide--image::before {
  display: none
}
.fancybox-slide--html {
  padding: 6px
}
.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle
}
.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(.5,0,.14,1);
  -webkit-backface-visibility: hidden;
  background: 0 0/100% 100% no-repeat;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform,opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995
}
.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out
}
.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in
}
.fancybox-can-pan .fancybox-content,
.fancybox-can-swipe .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab
}
.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing
}
.fancybox-container [data-selectable=true] {
  cursor: text
}
.fancybox-image,
.fancybox-spaceball {
  background: 0 0;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%
}
.fancybox-spaceball {
  z-index: 1
}
.fancybox-slide--iframe .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--video .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%
}
.fancybox-slide--video .fancybox-content {
  background: #000
}
.fancybox-slide--map .fancybox-content {
  background: #e5e3df
}
.fancybox-slide--iframe .fancybox-content {
  background: #fff
}
.fancybox-iframe,
.fancybox-video {
  background: 0 0;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%
}
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0
}
.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%
}
.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0
}
.fancybox-button {
  background: rgba(30,30,30,.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px
}
.fancybox-button,
.fancybox-button:link,
.fancybox-button:visited {
  color: #ccc
}
.fancybox-button:hover {
  color: #fff
}
.fancybox-button:focus {
  outline: 0
}
.fancybox-button.fancybox-focus {
  outline: dotted 1px
}
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: 0
}
.fancybox-button div {
  height: 100%
}
.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%
}
.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0
}
.fancybox-button--fsenter svg:nth-child(2),
.fancybox-button--fsexit svg:nth-child(1),
.fancybox-button--pause svg:nth-child(1),
.fancybox-button--play svg:nth-child(2) {
  display: none
}
.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998
}
.fancybox-close-small {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401
}
.fancybox-close-small:hover {
  color: #fff;
  opacity: 1
}
.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0
}
.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden
}
.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none
}
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px
}
.fancybox-navigation .fancybox-button div {
  padding: 7px
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px
}
.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right)
}
.fancybox-caption {
  background: linear-gradient(to top,rgba(0,0,0,.85) 0,rgba(0,0,0,.3) 50%,rgba(0,0,0,.15) 65%,rgba(0,0,0,.075) 75.5%,rgba(0,0,0,.037) 82.85%,rgba(0,0,0,.019) 88%,rgba(0,0,0,0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996
}
@supports (padding:max(0px)) {
  .fancybox-caption {
    padding: 75px max(44px,env(safe-area-inset-right)) max(25px,env(safe-area-inset-bottom)) max(44px,env(safe-area-inset-left))
  }
}
.fancybox-caption--separate {
  margin-top: -50px
}
.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all
}
.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none
}
.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline
}
.fancybox-loading {
  animation: 1s linear infinite fancybox-rotate;
  background: 0 0;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999
}
@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg)
  }
}
.fancybox-animated {
  transition-timing-function: cubic-bezier(0,0,.25,1)
}
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%,0,0)
}
.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%,0,0)
}
.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0,0,0)
}
.fancybox-fx-fade.fancybox-slide--next,
.fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(.19,1,.22,1)
}
.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1
}
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5,1.5,1.5)
}
.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(.5,.5,.5)
}
.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1,1,1)
}
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg)
}
.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg)
}
.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0);
  transform: rotate(0)
}
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0,0,0) translate3d(-100%,0,0)
}
.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0,0,0) translate3d(100%,0,0)
}
.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1,1,1) translate3d(0,0,0)
}
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%,0,0) scale(.1) skew(-10deg)
}
.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%,0,0) scale(.1) skew(10deg)
}
.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0,0,0) scale(1)
}
@media all and (max-height:576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px
  }
  .fancybox-slide--image {
    padding: 6px 0
  }
  .fancybox-close-small {
    right: -6px
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px
  }
  @supports (padding:max(0px)) {
    .fancybox-caption {
      padding-left: max(12px,env(safe-area-inset-left));
      padding-right: max(12px,env(safe-area-inset-right))
    }
  }
}
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center
}
.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px
}
.fancybox-share p {
  margin: 0;
  padding: 0
}
.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap
}
.fancybox-share__button:link,
.fancybox-share__button:visited {
  color: #fff
}
.fancybox-share__button:hover {
  text-decoration: none
}
.fancybox-share__button--fb {
  background: #3b5998
}
.fancybox-share__button--fb:hover {
  background: #344e86
}
.fancybox-share__button--pt {
  background: #bd081d
}
.fancybox-share__button--pt:hover {
  background: #aa0719
}
.fancybox-share__button--tw {
  background: #1da1f2
}
.fancybox-share__button--tw:hover {
  background: #0d95e8
}
.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px
}
.fancybox-share__button svg path {
  fill: #fff
}
.fancybox-share__input {
  background: 0 0;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0;
  outline: 0;
  padding: 10px 15px;
  width: 100%
}
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995
}
.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden
}
.fancybox-show-thumbs .fancybox-thumbs {
  display: block
}
.fancybox-show-thumbs .fancybox-inner {
  right: 212px
}
.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: 100%
}
.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3)
}
.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px
}
.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0,0,0,.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px
}
.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .2s cubic-bezier(.25,.46,.45,.94);
  z-index: 99991
}
.fancybox-thumbs__list a:focus::before {
  opacity: .5
}
.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1
}
@media all and (max-width:576px) {
  .fancybox-thumbs {
    width: 110px
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px)
  }
}
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-family: Arial,sans-serif
}
.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: 0!important
}
.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0
}
.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0
}
.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1
}
.irs-handle.type_last {
  z-index: 2
}
.irs-max,
.irs-min {
  position: absolute;
  display: block;
  cursor: default
}
.irs-min {
  left: 0
}
.irs-max {
  right: 0
}
.irs-from,
.irs-single,
.irs-to {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap
}
.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px
}
.irs-with-grid .irs-grid {
  display: block
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000
}
.irs-grid-pol.small {
  height: 4px
}
.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000
}
.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0,0,0,0);
  z-index: 2
}
.lt-ie9 .irs-disable-mask {
  background: #000;
  cursor: not-allowed
}
.irs-disabled {
  opacity: .4
}
.irs-hidden-input {
  position: absolute!important;
  display: block!important;
  top: 0!important;
  left: 0!important;
  width: 0!important;
  height: 0!important;
  font-size: 0!important;
  line-height: 0!important;
  padding: 0!important;
  margin: 0!important;
  overflow: hidden;
  outline: 0!important;
  z-index: -9999!important;
  background: 0 0!important;
  border-style: solid!important;
  border-color: transparent!important
}
.irs--flat {
  height: 40px
}
.irs--flat.irs-with-grid {
  height: 60px
}
.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px
}
.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #ed5565
}
.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px
}
.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9
}
.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #da4453
}
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540
}
.irs--flat .irs-max,
.irs--flat .irs-min {
  top: 0;
  padding: 1px 3px;
  color: #828282;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px
}
.irs--flat .irs-from,
.irs--flat .irs-single,
.irs--flat .irs-to {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px
}
.irs--flat .irs-from:before,
.irs--flat .irs-single:before,
.irs--flat .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565
}
.irs--flat .irs-grid-pol {
  background-color: #e1e4e9
}
.irs--flat .irs-grid-text {
  color: #828282
}
.irs--big {
  height: 55px
}
.irs--big.irs-with-grid {
  height: 70px
}
.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: #fff;
  background: linear-gradient(to bottom,#ddd -50%,#fff 150%);
  border: 1px solid #ccc;
  border-radius: 12px
}
.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom,#fff 0,#428bca 30%,#b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255,255,255,.5)
}
.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px
}
.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66,139,202,.5)
}
.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0,0,0,.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom,#fff 0,#b4b9be 30%,#fff 100%);
  box-shadow: 1px 1px 2px rgba(0,0,0,.2),inset 0 0 3px 1px #fff;
  border-radius: 30px
}
.irs--big .irs-handle.state_hover,
.irs--big .irs-handle:hover {
  border-color: rgba(0,0,0,.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom,#fff 0,#919ba5 30%,#fff 100%)
}
.irs--big .irs-max,
.irs--big .irs-min {
  top: 0;
  padding: 1px 5px;
  color: #fff;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px
}
.irs--big .irs-from,
.irs--big .irs-single,
.irs--big .irs-to {
  color: #fff;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom,#428bca 0,#3071a9 100%);
  border-radius: 3px
}
.irs--big .irs-grid-pol {
  background-color: #428bca
}
.irs--big .irs-grid-text {
  color: #428bca
}
.irs--modern,
.irs--modern.irs-with-grid {
  height: 55px
}
.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom,#e0e4ea 0,#d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px
}
.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom,#20b426 0,#18891d 100%)
}
.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px
}
.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209,214,224,.5)
}
.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0,0,0,.1);
  border-radius: 0 0 3px 3px
}
.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: #fff;
  transform: rotate(45deg)
}
.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom,#fff 0,#e9e6e6 100%);
  border-radius: 0 0 3px 3px
}
.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1
}
.irs--modern .irs-handle.state_hover,
.irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom,#fff 0,#919ba5 30%,#fff 100%)
}
.irs--modern .irs-handle.state_hover > i:nth-child(1),
.irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2
}
.irs--modern .irs-handle.state_hover > i:nth-child(3),
.irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a
}
.irs--modern .irs-max,
.irs--modern .irs-min {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: #fff;
  background-color: #d1d6e0;
  border-radius: 5px
}
.irs--modern .irs-from,
.irs--modern .irs-single,
.irs--modern .irs-to {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: #fff;
  border-radius: 5px
}
.irs--modern .irs-from:before,
.irs--modern .irs-single:before,
.irs--modern .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426
}
.irs--modern .irs-grid {
  height: 25px
}
.irs--modern .irs-grid-pol {
  background-color: #dedede
}
.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px
}
.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1
}
.irs--sharp.irs-with-grid {
  height: 57px
}
.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: #000;
  border-radius: 2px
}
.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa
}
.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px
}
.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0,0,0,.5)
}
.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2
}
.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2
}
.irs--sharp .irs-handle.state_hover,
.irs--sharp .irs-handle:hover {
  background-color: #000
}
.irs--sharp .irs-handle.state_hover > i:first-child,
.irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: #000
}
.irs--sharp .irs-max,
.irs--sharp .irs-min {
  color: #fff;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: .4;
  background-color: #a804b2;
  border-radius: 2px
}
.irs--sharp .irs-from,
.irs--sharp .irs-single,
.irs--sharp .irs-to {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: #fff;
  border-radius: 2px
}
.irs--sharp .irs-from:before,
.irs--sharp .irs-single:before,
.irs--sharp .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2
}
.irs--sharp .irs-grid {
  height: 25px
}
.irs--sharp .irs-grid-pol {
  background-color: #dedede
}
.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px
}
.irs--round {
  height: 50px
}
.irs--round.irs-with-grid {
  height: 65px
}
.irs--round .irs-line {
  top: 36px;
  height: 4px;
  border-radius: 4px
}
.irs--round .irs-bar {
  top: 36px;
  height: 4px
}
.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px
}
.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222,228,236,.5)
}
.irs--round .irs-handle {
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0,0,255,.3)
}
.irs--round .irs-max,
.irs--round .irs-min {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0,0,0,.1);
  border-radius: 4px
}
.irs--round .irs-from,
.irs--round .irs-single,
.irs--round .irs-to {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  color: #fff;
  border-radius: 4px
}
.irs--round .irs-from:before,
.irs--round .irs-single:before,
.irs--round .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent
}
.irs--round .irs-grid {
  height: 25px
}
.irs--round .irs-grid-pol {
  background-color: #dedede
}
.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px
}
.irs--square {
  height: 50px
}
.irs--square.irs-with-grid {
  height: 60px
}
.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede
}
.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: #000
}
.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede
}
.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid #000;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}
.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #f0f6ff
}
.irs--square .irs-max,
.irs--square .irs-min {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0,0,0,.1)
}
.irs--square .irs-from,
.irs--square .irs-single,
.irs--square .irs-to {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #000;
  color: #fff
}
.irs--square .irs-grid {
  height: 25px
}
.irs--square .irs-grid-pol {
  background-color: #dedede
}
.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px
}
.jarallax {
  position: relative;
  z-index: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover
}
.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1
}
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  line-height: 40px;
  outline: 0;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left!important;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: auto
}
.nice-select:hover {
  border-color: #dbdbdb
}
.nice-select.open,
.nice-select:active,
.nice-select:focus {
  border-color: #828282
}
.nice-select:after {
  border-bottom: 2px solid #828282;
  border-right: 2px solid #828282;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  width: 5px
}
.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg)
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0)
}
.nice-select.disabled {
  border-color: #ededed;
  color: #828282;
  pointer-events: none
}
.nice-select.disabled:after {
  border-color: #ccc
}
.nice-select.wide {
  width: 100%
}
.nice-select.wide .list {
  left: 0!important;
  right: 0!important
}
.nice-select.right {
  float: right
}
.nice-select.right .list {
  left: auto;
  right: 0
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px
}
.nice-select.small:after {
  height: 4px;
  width: 4px
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px
}
.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68,68,68,.11);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(.75) translateY(-21px);
  -ms-transform: scale(.75) translateY(-21px);
  transform: scale(.75) translateY(-21px);
  -webkit-transition: .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
  transition: .2s cubic-bezier(.5,0,0,1.25),opacity .15s ease-out;
  z-index: 9
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent!important
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: 0;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: .2s;
  transition: .2s
}
.nice-select .option.focus,
.nice-select .option.selected.focus,
.nice-select .option:hover {
  background-color: #f6f6f6
}
.nice-select .option.selected {
  font-weight: 700
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #828282;
  cursor: default
}
.no-csspointerevents .nice-select .list {
  display: none
}
.no-csspointerevents .nice-select.open .list {
  display: block
}
[data-aos][data-aos][data-aos-duration='50'],
body[data-aos-duration='50'] [data-aos] {
  transition-duration: 50ms
}
[data-aos][data-aos][data-aos-delay='50'],
body[data-aos-delay='50'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='50'].aos-animate,
body[data-aos-delay='50'] [data-aos].aos-animate {
  transition-delay: 50ms
}
[data-aos][data-aos][data-aos-duration='100'],
body[data-aos-duration='100'] [data-aos] {
  transition-duration: .1s
}
[data-aos][data-aos][data-aos-delay='100'],
body[data-aos-delay='100'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='100'].aos-animate,
body[data-aos-delay='100'] [data-aos].aos-animate {
  transition-delay: .1s
}
[data-aos][data-aos][data-aos-duration='150'],
body[data-aos-duration='150'] [data-aos] {
  transition-duration: 150ms
}
[data-aos][data-aos][data-aos-delay='150'],
body[data-aos-delay='150'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='150'].aos-animate,
body[data-aos-delay='150'] [data-aos].aos-animate {
  transition-delay: 150ms
}
[data-aos][data-aos][data-aos-duration='200'],
body[data-aos-duration='200'] [data-aos] {
  transition-duration: .2s
}
[data-aos][data-aos][data-aos-delay='200'],
body[data-aos-delay='200'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='200'].aos-animate,
body[data-aos-delay='200'] [data-aos].aos-animate {
  transition-delay: .2s
}
[data-aos][data-aos][data-aos-duration='250'],
body[data-aos-duration='250'] [data-aos] {
  transition-duration: 250ms
}
[data-aos][data-aos][data-aos-delay='250'],
body[data-aos-delay='250'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='250'].aos-animate,
body[data-aos-delay='250'] [data-aos].aos-animate {
  transition-delay: 250ms
}
[data-aos][data-aos][data-aos-duration='300'],
body[data-aos-duration='300'] [data-aos] {
  transition-duration: .3s
}
[data-aos][data-aos][data-aos-delay='300'],
body[data-aos-delay='300'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='300'].aos-animate,
body[data-aos-delay='300'] [data-aos].aos-animate {
  transition-delay: .3s
}
[data-aos][data-aos][data-aos-duration='350'],
body[data-aos-duration='350'] [data-aos] {
  transition-duration: 350ms
}
[data-aos][data-aos][data-aos-delay='350'],
body[data-aos-delay='350'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='350'].aos-animate,
body[data-aos-delay='350'] [data-aos].aos-animate {
  transition-delay: 350ms
}
[data-aos][data-aos][data-aos-duration='400'],
body[data-aos-duration='400'] [data-aos] {
  transition-duration: .4s
}
[data-aos][data-aos][data-aos-delay='400'],
body[data-aos-delay='400'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='400'].aos-animate,
body[data-aos-delay='400'] [data-aos].aos-animate {
  transition-delay: .4s
}
[data-aos][data-aos][data-aos-duration='450'],
body[data-aos-duration='450'] [data-aos] {
  transition-duration: 450ms
}
[data-aos][data-aos][data-aos-delay='450'],
body[data-aos-delay='450'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='450'].aos-animate,
body[data-aos-delay='450'] [data-aos].aos-animate {
  transition-delay: 450ms
}
[data-aos][data-aos][data-aos-duration='500'],
body[data-aos-duration='500'] [data-aos] {
  transition-duration: .5s
}
[data-aos][data-aos][data-aos-delay='500'],
body[data-aos-delay='500'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='500'].aos-animate,
body[data-aos-delay='500'] [data-aos].aos-animate {
  transition-delay: .5s
}
[data-aos][data-aos][data-aos-duration='550'],
body[data-aos-duration='550'] [data-aos] {
  transition-duration: 550ms
}
[data-aos][data-aos][data-aos-delay='550'],
body[data-aos-delay='550'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='550'].aos-animate,
body[data-aos-delay='550'] [data-aos].aos-animate {
  transition-delay: 550ms
}
[data-aos][data-aos][data-aos-duration='600'],
body[data-aos-duration='600'] [data-aos] {
  transition-duration: .6s
}
[data-aos][data-aos][data-aos-delay='600'],
body[data-aos-delay='600'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='600'].aos-animate,
body[data-aos-delay='600'] [data-aos].aos-animate {
  transition-delay: .6s
}
[data-aos][data-aos][data-aos-duration='650'],
body[data-aos-duration='650'] [data-aos] {
  transition-duration: 650ms
}
[data-aos][data-aos][data-aos-delay='650'],
body[data-aos-delay='650'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='650'].aos-animate,
body[data-aos-delay='650'] [data-aos].aos-animate {
  transition-delay: 650ms
}
[data-aos][data-aos][data-aos-duration='700'],
body[data-aos-duration='700'] [data-aos] {
  transition-duration: .7s
}
[data-aos][data-aos][data-aos-delay='700'],
body[data-aos-delay='700'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='700'].aos-animate,
body[data-aos-delay='700'] [data-aos].aos-animate {
  transition-delay: .7s
}
[data-aos][data-aos][data-aos-duration='750'],
body[data-aos-duration='750'] [data-aos] {
  transition-duration: 750ms
}
[data-aos][data-aos][data-aos-delay='750'],
body[data-aos-delay='750'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='750'].aos-animate,
body[data-aos-delay='750'] [data-aos].aos-animate {
  transition-delay: 750ms
}
[data-aos][data-aos][data-aos-duration='800'],
body[data-aos-duration='800'] [data-aos] {
  transition-duration: .8s
}
[data-aos][data-aos][data-aos-delay='800'],
body[data-aos-delay='800'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='800'].aos-animate,
body[data-aos-delay='800'] [data-aos].aos-animate {
  transition-delay: .8s
}
[data-aos][data-aos][data-aos-duration='850'],
body[data-aos-duration='850'] [data-aos] {
  transition-duration: 850ms
}
[data-aos][data-aos][data-aos-delay='850'],
body[data-aos-delay='850'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='850'].aos-animate,
body[data-aos-delay='850'] [data-aos].aos-animate {
  transition-delay: 850ms
}
[data-aos][data-aos][data-aos-duration='900'],
body[data-aos-duration='900'] [data-aos] {
  transition-duration: .9s
}
[data-aos][data-aos][data-aos-delay='900'],
body[data-aos-delay='900'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='900'].aos-animate,
body[data-aos-delay='900'] [data-aos].aos-animate {
  transition-delay: .9s
}
[data-aos][data-aos][data-aos-duration='950'],
body[data-aos-duration='950'] [data-aos] {
  transition-duration: 950ms
}
[data-aos][data-aos][data-aos-delay='950'],
body[data-aos-delay='950'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='950'].aos-animate,
body[data-aos-delay='950'] [data-aos].aos-animate {
  transition-delay: 950ms
}
[data-aos][data-aos][data-aos-duration='1000'],
body[data-aos-duration='1000'] [data-aos] {
  transition-duration: 1s
}
[data-aos][data-aos][data-aos-delay='1000'],
body[data-aos-delay='1000'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1000'].aos-animate,
body[data-aos-delay='1000'] [data-aos].aos-animate {
  transition-delay: 1s
}
[data-aos][data-aos][data-aos-duration='1050'],
body[data-aos-duration='1050'] [data-aos] {
  transition-duration: 1.05s
}
[data-aos][data-aos][data-aos-delay='1050'],
body[data-aos-delay='1050'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1050'].aos-animate,
body[data-aos-delay='1050'] [data-aos].aos-animate {
  transition-delay: 1.05s
}
[data-aos][data-aos][data-aos-duration='1100'],
body[data-aos-duration='1100'] [data-aos] {
  transition-duration: 1.1s
}
[data-aos][data-aos][data-aos-delay='1100'],
body[data-aos-delay='1100'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1100'].aos-animate,
body[data-aos-delay='1100'] [data-aos].aos-animate {
  transition-delay: 1.1s
}
[data-aos][data-aos][data-aos-duration='1150'],
body[data-aos-duration='1150'] [data-aos] {
  transition-duration: 1.15s
}
[data-aos][data-aos][data-aos-delay='1150'],
body[data-aos-delay='1150'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1150'].aos-animate,
body[data-aos-delay='1150'] [data-aos].aos-animate {
  transition-delay: 1.15s
}
[data-aos][data-aos][data-aos-duration='1200'],
body[data-aos-duration='1200'] [data-aos] {
  transition-duration: 1.2s
}
[data-aos][data-aos][data-aos-delay='1200'],
body[data-aos-delay='1200'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1200'].aos-animate,
body[data-aos-delay='1200'] [data-aos].aos-animate {
  transition-delay: 1.2s
}
[data-aos][data-aos][data-aos-duration='1250'],
body[data-aos-duration='1250'] [data-aos] {
  transition-duration: 1.25s
}
[data-aos][data-aos][data-aos-delay='1250'],
body[data-aos-delay='1250'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1250'].aos-animate,
body[data-aos-delay='1250'] [data-aos].aos-animate {
  transition-delay: 1.25s
}
[data-aos][data-aos][data-aos-duration='1300'],
body[data-aos-duration='1300'] [data-aos] {
  transition-duration: 1.3s
}
[data-aos][data-aos][data-aos-delay='1300'],
body[data-aos-delay='1300'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1300'].aos-animate,
body[data-aos-delay='1300'] [data-aos].aos-animate {
  transition-delay: 1.3s
}
[data-aos][data-aos][data-aos-duration='1350'],
body[data-aos-duration='1350'] [data-aos] {
  transition-duration: 1.35s
}
[data-aos][data-aos][data-aos-delay='1350'],
body[data-aos-delay='1350'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1350'].aos-animate,
body[data-aos-delay='1350'] [data-aos].aos-animate {
  transition-delay: 1.35s
}
[data-aos][data-aos][data-aos-duration='1400'],
body[data-aos-duration='1400'] [data-aos] {
  transition-duration: 1.4s
}
[data-aos][data-aos][data-aos-delay='1400'],
body[data-aos-delay='1400'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1400'].aos-animate,
body[data-aos-delay='1400'] [data-aos].aos-animate {
  transition-delay: 1.4s
}
[data-aos][data-aos][data-aos-duration='1450'],
body[data-aos-duration='1450'] [data-aos] {
  transition-duration: 1.45s
}
[data-aos][data-aos][data-aos-delay='1450'],
body[data-aos-delay='1450'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1450'].aos-animate,
body[data-aos-delay='1450'] [data-aos].aos-animate {
  transition-delay: 1.45s
}
[data-aos][data-aos][data-aos-duration='1500'],
body[data-aos-duration='1500'] [data-aos] {
  transition-duration: 1.5s
}
[data-aos][data-aos][data-aos-delay='1500'],
body[data-aos-delay='1500'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1500'].aos-animate,
body[data-aos-delay='1500'] [data-aos].aos-animate {
  transition-delay: 1.5s
}
[data-aos][data-aos][data-aos-duration='1550'],
body[data-aos-duration='1550'] [data-aos] {
  transition-duration: 1.55s
}
[data-aos][data-aos][data-aos-delay='1550'],
body[data-aos-delay='1550'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1550'].aos-animate,
body[data-aos-delay='1550'] [data-aos].aos-animate {
  transition-delay: 1.55s
}
[data-aos][data-aos][data-aos-duration='1600'],
body[data-aos-duration='1600'] [data-aos] {
  transition-duration: 1.6s
}
[data-aos][data-aos][data-aos-delay='1600'],
body[data-aos-delay='1600'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1600'].aos-animate,
body[data-aos-delay='1600'] [data-aos].aos-animate {
  transition-delay: 1.6s
}
[data-aos][data-aos][data-aos-duration='1650'],
body[data-aos-duration='1650'] [data-aos] {
  transition-duration: 1.65s
}
[data-aos][data-aos][data-aos-delay='1650'],
body[data-aos-delay='1650'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1650'].aos-animate,
body[data-aos-delay='1650'] [data-aos].aos-animate {
  transition-delay: 1.65s
}
[data-aos][data-aos][data-aos-duration='1700'],
body[data-aos-duration='1700'] [data-aos] {
  transition-duration: 1.7s
}
[data-aos][data-aos][data-aos-delay='1700'],
body[data-aos-delay='1700'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1700'].aos-animate,
body[data-aos-delay='1700'] [data-aos].aos-animate {
  transition-delay: 1.7s
}
[data-aos][data-aos][data-aos-duration='1750'],
body[data-aos-duration='1750'] [data-aos] {
  transition-duration: 1.75s
}
[data-aos][data-aos][data-aos-delay='1750'],
body[data-aos-delay='1750'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1750'].aos-animate,
body[data-aos-delay='1750'] [data-aos].aos-animate {
  transition-delay: 1.75s
}
[data-aos][data-aos][data-aos-duration='1800'],
body[data-aos-duration='1800'] [data-aos] {
  transition-duration: 1.8s
}
[data-aos][data-aos][data-aos-delay='1800'],
body[data-aos-delay='1800'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1800'].aos-animate,
body[data-aos-delay='1800'] [data-aos].aos-animate {
  transition-delay: 1.8s
}
[data-aos][data-aos][data-aos-duration='1850'],
body[data-aos-duration='1850'] [data-aos] {
  transition-duration: 1.85s
}
[data-aos][data-aos][data-aos-delay='1850'],
body[data-aos-delay='1850'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1850'].aos-animate,
body[data-aos-delay='1850'] [data-aos].aos-animate {
  transition-delay: 1.85s
}
[data-aos][data-aos][data-aos-duration='1900'],
body[data-aos-duration='1900'] [data-aos] {
  transition-duration: 1.9s
}
[data-aos][data-aos][data-aos-delay='1900'],
body[data-aos-delay='1900'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1900'].aos-animate,
body[data-aos-delay='1900'] [data-aos].aos-animate {
  transition-delay: 1.9s
}
[data-aos][data-aos][data-aos-duration='1950'],
body[data-aos-duration='1950'] [data-aos] {
  transition-duration: 1.95s
}
[data-aos][data-aos][data-aos-delay='1950'],
body[data-aos-delay='1950'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1950'].aos-animate,
body[data-aos-delay='1950'] [data-aos].aos-animate {
  transition-delay: 1.95s
}
[data-aos][data-aos][data-aos-duration='2000'],
body[data-aos-duration='2000'] [data-aos] {
  transition-duration: 2s
}
[data-aos][data-aos][data-aos-delay='2000'],
body[data-aos-delay='2000'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2000'].aos-animate,
body[data-aos-delay='2000'] [data-aos].aos-animate {
  transition-delay: 2s
}
[data-aos][data-aos][data-aos-duration='2050'],
body[data-aos-duration='2050'] [data-aos] {
  transition-duration: 2.05s
}
[data-aos][data-aos][data-aos-delay='2050'],
body[data-aos-delay='2050'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2050'].aos-animate,
body[data-aos-delay='2050'] [data-aos].aos-animate {
  transition-delay: 2.05s
}
[data-aos][data-aos][data-aos-duration='2100'],
body[data-aos-duration='2100'] [data-aos] {
  transition-duration: 2.1s
}
[data-aos][data-aos][data-aos-delay='2100'],
body[data-aos-delay='2100'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2100'].aos-animate,
body[data-aos-delay='2100'] [data-aos].aos-animate {
  transition-delay: 2.1s
}
[data-aos][data-aos][data-aos-duration='2150'],
body[data-aos-duration='2150'] [data-aos] {
  transition-duration: 2.15s
}
[data-aos][data-aos][data-aos-delay='2150'],
body[data-aos-delay='2150'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2150'].aos-animate,
body[data-aos-delay='2150'] [data-aos].aos-animate {
  transition-delay: 2.15s
}
[data-aos][data-aos][data-aos-duration='2200'],
body[data-aos-duration='2200'] [data-aos] {
  transition-duration: 2.2s
}
[data-aos][data-aos][data-aos-delay='2200'],
body[data-aos-delay='2200'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2200'].aos-animate,
body[data-aos-delay='2200'] [data-aos].aos-animate {
  transition-delay: 2.2s
}
[data-aos][data-aos][data-aos-duration='2250'],
body[data-aos-duration='2250'] [data-aos] {
  transition-duration: 2.25s
}
[data-aos][data-aos][data-aos-delay='2250'],
body[data-aos-delay='2250'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2250'].aos-animate,
body[data-aos-delay='2250'] [data-aos].aos-animate {
  transition-delay: 2.25s
}
[data-aos][data-aos][data-aos-duration='2300'],
body[data-aos-duration='2300'] [data-aos] {
  transition-duration: 2.3s
}
[data-aos][data-aos][data-aos-delay='2300'],
body[data-aos-delay='2300'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2300'].aos-animate,
body[data-aos-delay='2300'] [data-aos].aos-animate {
  transition-delay: 2.3s
}
[data-aos][data-aos][data-aos-duration='2350'],
body[data-aos-duration='2350'] [data-aos] {
  transition-duration: 2.35s
}
[data-aos][data-aos][data-aos-delay='2350'],
body[data-aos-delay='2350'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2350'].aos-animate,
body[data-aos-delay='2350'] [data-aos].aos-animate {
  transition-delay: 2.35s
}
[data-aos][data-aos][data-aos-duration='2400'],
body[data-aos-duration='2400'] [data-aos] {
  transition-duration: 2.4s
}
[data-aos][data-aos][data-aos-delay='2400'],
body[data-aos-delay='2400'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2400'].aos-animate,
body[data-aos-delay='2400'] [data-aos].aos-animate {
  transition-delay: 2.4s
}
[data-aos][data-aos][data-aos-duration='2450'],
body[data-aos-duration='2450'] [data-aos] {
  transition-duration: 2.45s
}
[data-aos][data-aos][data-aos-delay='2450'],
body[data-aos-delay='2450'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2450'].aos-animate,
body[data-aos-delay='2450'] [data-aos].aos-animate {
  transition-delay: 2.45s
}
[data-aos][data-aos][data-aos-duration='2500'],
body[data-aos-duration='2500'] [data-aos] {
  transition-duration: 2.5s
}
[data-aos][data-aos][data-aos-delay='2500'],
body[data-aos-delay='2500'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2500'].aos-animate,
body[data-aos-delay='2500'] [data-aos].aos-animate {
  transition-delay: 2.5s
}
[data-aos][data-aos][data-aos-duration='2550'],
body[data-aos-duration='2550'] [data-aos] {
  transition-duration: 2.55s
}
[data-aos][data-aos][data-aos-delay='2550'],
body[data-aos-delay='2550'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2550'].aos-animate,
body[data-aos-delay='2550'] [data-aos].aos-animate {
  transition-delay: 2.55s
}
[data-aos][data-aos][data-aos-duration='2600'],
body[data-aos-duration='2600'] [data-aos] {
  transition-duration: 2.6s
}
[data-aos][data-aos][data-aos-delay='2600'],
body[data-aos-delay='2600'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2600'].aos-animate,
body[data-aos-delay='2600'] [data-aos].aos-animate {
  transition-delay: 2.6s
}
[data-aos][data-aos][data-aos-duration='2650'],
body[data-aos-duration='2650'] [data-aos] {
  transition-duration: 2.65s
}
[data-aos][data-aos][data-aos-delay='2650'],
body[data-aos-delay='2650'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2650'].aos-animate,
body[data-aos-delay='2650'] [data-aos].aos-animate {
  transition-delay: 2.65s
}
[data-aos][data-aos][data-aos-duration='2700'],
body[data-aos-duration='2700'] [data-aos] {
  transition-duration: 2.7s
}
[data-aos][data-aos][data-aos-delay='2700'],
body[data-aos-delay='2700'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2700'].aos-animate,
body[data-aos-delay='2700'] [data-aos].aos-animate {
  transition-delay: 2.7s
}
[data-aos][data-aos][data-aos-duration='2750'],
body[data-aos-duration='2750'] [data-aos] {
  transition-duration: 2.75s
}
[data-aos][data-aos][data-aos-delay='2750'],
body[data-aos-delay='2750'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2750'].aos-animate,
body[data-aos-delay='2750'] [data-aos].aos-animate {
  transition-delay: 2.75s
}
[data-aos][data-aos][data-aos-duration='2800'],
body[data-aos-duration='2800'] [data-aos] {
  transition-duration: 2.8s
}
[data-aos][data-aos][data-aos-delay='2800'],
body[data-aos-delay='2800'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2800'].aos-animate,
body[data-aos-delay='2800'] [data-aos].aos-animate {
  transition-delay: 2.8s
}
[data-aos][data-aos][data-aos-duration='2850'],
body[data-aos-duration='2850'] [data-aos] {
  transition-duration: 2.85s
}
[data-aos][data-aos][data-aos-delay='2850'],
body[data-aos-delay='2850'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2850'].aos-animate,
body[data-aos-delay='2850'] [data-aos].aos-animate {
  transition-delay: 2.85s
}
[data-aos][data-aos][data-aos-duration='2900'],
body[data-aos-duration='2900'] [data-aos] {
  transition-duration: 2.9s
}
[data-aos][data-aos][data-aos-delay='2900'],
body[data-aos-delay='2900'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2900'].aos-animate,
body[data-aos-delay='2900'] [data-aos].aos-animate {
  transition-delay: 2.9s
}
[data-aos][data-aos][data-aos-duration='2950'],
body[data-aos-duration='2950'] [data-aos] {
  transition-duration: 2.95s
}
[data-aos][data-aos][data-aos-delay='2950'],
body[data-aos-delay='2950'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2950'].aos-animate,
body[data-aos-delay='2950'] [data-aos].aos-animate {
  transition-delay: 2.95s
}
[data-aos][data-aos][data-aos-duration='3000'],
body[data-aos-duration='3000'] [data-aos] {
  transition-duration: 3s
}
[data-aos][data-aos][data-aos-delay='3000'],
body[data-aos-delay='3000'] [data-aos] {
  transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='3000'].aos-animate,
body[data-aos-delay='3000'] [data-aos].aos-animate {
  transition-delay: 3s
}
[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(.25,.25,.75,.75)
}
[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: cubic-bezier(.25,.1,.25,1)
}
[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: cubic-bezier(.42,0,1,1)
}
[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: cubic-bezier(0,0,.58,1)
}
[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: cubic-bezier(.42,0,.58,1)
}
[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(.6,-.28,.735,.045)
}
[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(.175,.885,.32,1.275)
}
[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(.68,-.55,.265,1.55)
}
[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(.47,0,.745,.715)
}
[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(.39,.575,.565,1)
}
[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(.445,.05,.55,.95)
}
[data-aos][data-aos][data-aos-easing=ease-in-cubic],
[data-aos][data-aos][data-aos-easing=ease-in-quad],
[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-cubic] [data-aos],
body[data-aos-easing=ease-in-quad] [data-aos],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(.55,.085,.68,.53)
}
[data-aos][data-aos][data-aos-easing=ease-out-cubic],
[data-aos][data-aos][data-aos-easing=ease-out-quad],
[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-cubic] [data-aos],
body[data-aos-easing=ease-out-quad] [data-aos],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(.25,.46,.45,.94)
}
[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-cubic] [data-aos],
body[data-aos-easing=ease-in-out-quad] [data-aos],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(.455,.03,.515,.955)
}
[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform
}
[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translate3d(0,0,0)
}
[data-aos=fade-up] {
  transform: translate3d(0,100px,0)
}
[data-aos=fade-down] {
  transform: translate3d(0,-100px,0)
}
[data-aos=fade-right] {
  transform: translate3d(-100px,0,0)
}
[data-aos=fade-left] {
  transform: translate3d(100px,0,0)
}
[data-aos=fade-up-right] {
  transform: translate3d(-100px,100px,0)
}
[data-aos=fade-up-left] {
  transform: translate3d(100px,100px,0)
}
[data-aos=fade-down-right] {
  transform: translate3d(-100px,-100px,0)
}
[data-aos=fade-down-left] {
  transform: translate3d(100px,-100px,0)
}
[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform
}
[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translate3d(0,0,0) scale(1)
}
[data-aos=zoom-in] {
  transform: scale(.6)
}
[data-aos=zoom-in-up] {
  transform: translate3d(0,100px,0) scale(.6)
}
[data-aos=zoom-in-down] {
  transform: translate3d(0,-100px,0) scale(.6)
}
[data-aos=zoom-in-right] {
  transform: translate3d(-100px,0,0) scale(.6)
}
[data-aos=zoom-in-left] {
  transform: translate3d(100px,0,0) scale(.6)
}
[data-aos=zoom-out] {
  transform: scale(1.2)
}
[data-aos=zoom-out-up] {
  transform: translate3d(0,100px,0) scale(1.2)
}
[data-aos=zoom-out-down] {
  transform: translate3d(0,-100px,0) scale(1.2)
}
[data-aos=zoom-out-right] {
  transform: translate3d(-100px,0,0) scale(1.2)
}
[data-aos=zoom-out-left] {
  transform: translate3d(100px,0,0) scale(1.2)
}
[data-aos^=slide][data-aos^=slide] {
  transition-property: transform
}
[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translate3d(0,0,0)
}
[data-aos=slide-up] {
  transform: translate3d(0,100%,0)
}
[data-aos=slide-down] {
  transform: translate3d(0,-100%,0)
}
[data-aos=slide-right] {
  transform: translate3d(-100%,0,0)
}
[data-aos=slide-left] {
  transform: translate3d(100%,0,0)
}
[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform
}
[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg)
}
[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0)
}
[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg)
}
[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0)
}
[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg)
}
[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0)
}
[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg)
}
[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0)
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0
}
.slick-list:focus {
  outline: 0
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  -o-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0)
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}
.slick-track:after,
.slick-track:before {
  content: "";
  display: table
}
.slick-track:after {
  clear: both
}
.slick-loading .slick-track {
  visibility: hidden
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none
}
[dir=rtl] .slick-slide {
  float: right
}
.slick-slide img {
  display: block
}
.slick-slide.slick-loading img {
  display: none
}
.slick-slide.dragging img {
  pointer-events: none
}
.slick-initialized .slick-slide {
  display: block
}
.slick-loading .slick-slide {
  visibility: hidden
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}
.slick-arrow.slick-hidden {
  display: none
}
.gallery .__image img,
.gallery .__link,
.posts .__item .__image .__overlay,
.posts .__item .__image img,
.recipes .__image img,
.tab-container .tab-content__item,
.team .__item .__image img,
.v-align > * {
  width: 100%
}
.gallery .__image img,
.gallery .__link,
.posts .__item .__image .__overlay,
.posts .__item .__image img,
.recipes .__image img,
.tab-container .tab-content__item,
.team .__item .__image img,
.v-align,
.v-align:before {
  height: 100%
}
.v-align:before {
  width: 0;
  content: "";
  margin-left: -4.5px
}
.v-align > *,
.v-align:before {
  display: inline-block;
  vertical-align: middle
}
.gallery .__image img,
.gallery .__link,
.posts .__item .__image .__overlay,
.posts .__item .__image img,
.recipes .__image img,
.tab-container .tab-content__item,
.team .__item .__image img {
  position: absolute;
  top: 0;
  left: 0
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none
}
.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.25)
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}
.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6
}
.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}
.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}
.align-baseline {
  vertical-align: baseline!important
}
.align-top {
  vertical-align: top!important
}
.align-middle {
  vertical-align: middle!important
}
.align-bottom {
  vertical-align: bottom!important
}
.align-text-bottom {
  vertical-align: text-bottom!important
}
.align-text-top {
  vertical-align: text-top!important
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden
}
.embed-responsive::before {
  display: block;
  content: ""
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0
}
.embed-responsive-21by9::before {
  padding-top: 42.85714%
}
.embed-responsive-16by9::before {
  padding-top: 56.25%
}
.embed-responsive-4by3::before {
  padding-top: 75%
}
.embed-responsive-1by1::before {
  padding-top: 100%
}
.position-static {
  position: static!important
}
.position-relative {
  position: relative!important
}
.position-absolute {
  position: absolute!important
}
.position-fixed {
  position: fixed!important
}
.position-sticky {
  position: sticky!important
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}
@supports (position:sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}
.w-25 {
  width: 25%!important
}
.w-50 {
  width: 50%!important
}
.w-75 {
  width: 75%!important
}
.w-100 {
  width: 100%!important
}
.w-auto {
  width: auto!important
}
.h-25 {
  height: 25%!important
}
.h-50 {
  height: 50%!important
}
.h-75 {
  height: 75%!important
}
.h-100 {
  height: 100%!important
}
.h-auto {
  height: auto!important
}
.mw-100 {
  max-width: 100%!important
}
.mh-100 {
  max-height: 100%!important
}
.m-0 {
  margin: 0!important
}
.mt-0,
.my-0 {
  margin-top: 0!important
}
.mr-0,
.mx-0 {
  margin-right: 0!important
}
.mb-0,
.my-0 {
  margin-bottom: 0!important
}
.ml-0,
.mx-0 {
  margin-left: 0!important
}
.m-1 {
  margin: .5rem!important
}
.mt-1,
.my-1 {
  margin-top: .5rem!important
}
.mr-1,
.mx-1 {
  margin-right: .5rem!important
}
.mb-1,
.my-1 {
  margin-bottom: .5rem!important
}
.ml-1,
.mx-1 {
  margin-left: .5rem!important
}
.m-2 {
  margin: 1rem!important
}
.mt-2,
.my-2 {
  margin-top: 1rem!important
}
.mr-2,
.mx-2 {
  margin-right: 1rem!important
}
.mb-2,
.my-2 {
  margin-bottom: 1rem!important
}
.ml-2,
.mx-2 {
  margin-left: 1rem!important
}
.m-3 {
  margin: 1.5rem!important
}
.mt-3,
.my-3 {
  margin-top: 1.5rem!important
}
.mr-3,
.mx-3 {
  margin-right: 1.5rem!important
}
.mb-3,
.my-3 {
  margin-bottom: 1.5rem!important
}
.ml-3,
.mx-3 {
  margin-left: 1.5rem!important
}
.m-4 {
  margin: 2rem!important
}
.mt-4,
.my-4 {
  margin-top: 2rem!important
}
.mr-4,
.mx-4 {
  margin-right: 2rem!important
}
.mb-4,
.my-4 {
  margin-bottom: 2rem!important
}
.ml-4,
.mx-4 {
  margin-left: 2rem!important
}
.m-5 {
  margin: 2.5rem!important
}
.mt-5,
.my-5 {
  margin-top: 2.5rem!important
}
.mr-5,
.mx-5 {
  margin-right: 2.5rem!important
}
.mb-5,
.my-5 {
  margin-bottom: 2.5rem!important
}
.ml-5,
.mx-5 {
  margin-left: 2.5rem!important
}
.m-6 {
  margin: 3rem!important
}
.mt-6,
.my-6 {
  margin-top: 3rem!important
}
.mr-6,
.mx-6 {
  margin-right: 3rem!important
}
.mb-6,
.my-6 {
  margin-bottom: 3rem!important
}
.ml-6,
.mx-6 {
  margin-left: 3rem!important
}
.m-7 {
  margin: 3.5rem!important
}
.mt-7,
.my-7 {
  margin-top: 3.5rem!important
}
.mr-7,
.mx-7 {
  margin-right: 3.5rem!important
}
.mb-7,
.my-7 {
  margin-bottom: 3.5rem!important
}
.ml-7,
.mx-7 {
  margin-left: 3.5rem!important
}
.m-8 {
  margin: 4rem!important
}
.mt-8,
.my-8 {
  margin-top: 4rem!important
}
.mr-8,
.mx-8 {
  margin-right: 4rem!important
}
.mb-8,
.my-8 {
  margin-bottom: 4rem!important
}
.ml-8,
.mx-8 {
  margin-left: 4rem!important
}
.m-9 {
  margin: 4.5rem!important
}
.mt-9,
.my-9 {
  margin-top: 4.5rem!important
}
.mr-9,
.mx-9 {
  margin-right: 4.5rem!important
}
.mb-9,
.my-9 {
  margin-bottom: 4.5rem!important
}
.ml-9,
.mx-9 {
  margin-left: 4.5rem!important
}
.m-10 {
  margin: 5rem!important
}
.mt-10,
.my-10 {
  margin-top: 5rem!important
}
.mr-10,
.mx-10 {
  margin-right: 5rem!important
}
.mb-10,
.my-10 {
  margin-bottom: 5rem!important
}
.ml-10,
.mx-10 {
  margin-left: 5rem!important
}
.m-11 {
  margin: 5.5rem!important
}
.mt-11,
.my-11 {
  margin-top: 5.5rem!important
}
.mr-11,
.mx-11 {
  margin-right: 5.5rem!important
}
.mb-11,
.my-11 {
  margin-bottom: 5.5rem!important
}
.ml-11,
.mx-11 {
  margin-left: 5.5rem!important
}
.m-12 {
  margin: 6rem!important
}
.mt-12,
.my-12 {
  margin-top: 6rem!important
}
.mr-12,
.mx-12 {
  margin-right: 6rem!important
}
.mb-12,
.my-12 {
  margin-bottom: 6rem!important
}
.ml-12,
.mx-12 {
  margin-left: 6rem!important
}
.p-0 {
  padding: 0!important
}
.pt-0,
.py-0 {
  padding-top: 0!important
}
.pr-0,
.px-0 {
  padding-right: 0!important
}
.pb-0,
.py-0 {
  padding-bottom: 0!important
}
.pl-0,
.px-0 {
  padding-left: 0!important
}
.p-1 {
  padding: .5rem!important
}
.pt-1,
.py-1 {
  padding-top: .5rem!important
}
.pr-1,
.px-1 {
  padding-right: .5rem!important
}
.pb-1,
.py-1 {
  padding-bottom: .5rem!important
}
.pl-1,
.px-1 {
  padding-left: .5rem!important
}
.p-2 {
  padding: 1rem!important
}
.pt-2,
.py-2 {
  padding-top: 1rem!important
}
.pr-2,
.px-2 {
  padding-right: 1rem!important
}
.pb-2,
.py-2 {
  padding-bottom: 1rem!important
}
.pl-2,
.px-2 {
  padding-left: 1rem!important
}
.p-3 {
  padding: 1.5rem!important
}
.pt-3,
.py-3 {
  padding-top: 1.5rem!important
}
.pr-3,
.px-3 {
  padding-right: 1.5rem!important
}
.pb-3,
.py-3 {
  padding-bottom: 1.5rem!important
}
.pl-3,
.px-3 {
  padding-left: 1.5rem!important
}
.p-4 {
  padding: 2rem!important
}
.pt-4,
.py-4 {
  padding-top: 2rem!important
}
.pr-4,
.px-4 {
  padding-right: 2rem!important
}
.pb-4,
.py-4 {
  padding-bottom: 2rem!important
}
.pl-4,
.px-4 {
  padding-left: 2rem!important
}
.p-5 {
  padding: 2.5rem!important
}
.pt-5,
.py-5 {
  padding-top: 2.5rem!important
}
.pr-5,
.px-5 {
  padding-right: 2.5rem!important
}
.pb-5,
.py-5 {
  padding-bottom: 2.5rem!important
}
.pl-5,
.px-5 {
  padding-left: 2.5rem!important
}
.p-6 {
  padding: 3rem!important
}
.pt-6,
.py-6 {
  padding-top: 3rem!important
}
.pr-6,
.px-6 {
  padding-right: 3rem!important
}
.pb-6,
.py-6 {
  padding-bottom: 3rem!important
}
.pl-6,
.px-6 {
  padding-left: 3rem!important
}
.p-7 {
  padding: 3.5rem!important
}
.pt-7,
.py-7 {
  padding-top: 3.5rem!important
}
.pr-7,
.px-7 {
  padding-right: 3.5rem!important
}
.pb-7,
.py-7 {
  padding-bottom: 3.5rem!important
}
.pl-7,
.px-7 {
  padding-left: 3.5rem!important
}
.p-8 {
  padding: 4rem!important
}
.pt-8,
.py-8 {
  padding-top: 4rem!important
}
.pr-8,
.px-8 {
  padding-right: 4rem!important
}
.pb-8,
.py-8 {
  padding-bottom: 4rem!important
}
.pl-8,
.px-8 {
  padding-left: 4rem!important
}
.p-9 {
  padding: 4.5rem!important
}
.pt-9,
.py-9 {
  padding-top: 4.5rem!important
}
.pr-9,
.px-9 {
  padding-right: 4.5rem!important
}
.pb-9,
.py-9 {
  padding-bottom: 4.5rem!important
}
.pl-9,
.px-9 {
  padding-left: 4.5rem!important
}
.p-10 {
  padding: 5rem!important
}
.pt-10,
.py-10 {
  padding-top: 5rem!important
}
.pr-10,
.px-10 {
  padding-right: 5rem!important
}
.pb-10,
.py-10 {
  padding-bottom: 5rem!important
}
.pl-10,
.px-10 {
  padding-left: 5rem!important
}
.p-11 {
  padding: 5.5rem!important
}
.pt-11,
.py-11 {
  padding-top: 5.5rem!important
}
.pr-11,
.px-11 {
  padding-right: 5.5rem!important
}
.pb-11,
.py-11 {
  padding-bottom: 5.5rem!important
}
.pl-11,
.px-11 {
  padding-left: 5.5rem!important
}
.p-12 {
  padding: 6rem!important
}
.pt-12,
.py-12 {
  padding-top: 6rem!important
}
.pr-12,
.px-12 {
  padding-right: 6rem!important
}
.pb-12,
.py-12 {
  padding-bottom: 6rem!important
}
.pl-12,
.px-12 {
  padding-left: 6rem!important
}
.m-auto {
  margin: auto!important
}
.mt-auto,
.my-auto {
  margin-top: auto!important
}
.mr-auto,
.mx-auto {
  margin-right: auto!important
}
.mb-auto,
.my-auto {
  margin-bottom: auto!important
}
.ml-auto,
.mx-auto {
  margin-left: auto!important
}
@media (min-width:576px) {
  .m-sm-0 {
    margin: 0!important
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0!important
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0!important
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0!important
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0!important
  }
  .m-sm-1 {
    margin: .5rem!important
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: .5rem!important
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .5rem!important
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .5rem!important
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .5rem!important
  }
  .m-sm-2 {
    margin: 1rem!important
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem!important
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem!important
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem!important
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem!important
  }
  .m-sm-3 {
    margin: 1.5rem!important
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem!important
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem!important
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem!important
  }
  .m-sm-4 {
    margin: 2rem!important
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem!important
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem!important
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem!important
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem!important
  }
  .m-sm-5 {
    margin: 2.5rem!important
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.5rem!important
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.5rem!important
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.5rem!important
  }
  .m-sm-6 {
    margin: 3rem!important
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem!important
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem!important
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem!important
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem!important
  }
  .m-sm-7 {
    margin: 3.5rem!important
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.5rem!important
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.5rem!important
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.5rem!important
  }
  .m-sm-8 {
    margin: 4rem!important
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem!important
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4rem!important
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem!important
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4rem!important
  }
  .m-sm-9 {
    margin: 4.5rem!important
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.5rem!important
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.5rem!important
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.5rem!important
  }
  .m-sm-10 {
    margin: 5rem!important
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem!important
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem!important
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem!important
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem!important
  }
  .m-sm-11 {
    margin: 5.5rem!important
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.5rem!important
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.5rem!important
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.5rem!important
  }
  .m-sm-12 {
    margin: 6rem!important
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6rem!important
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6rem!important
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6rem!important
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6rem!important
  }
  .p-sm-0 {
    padding: 0!important
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0!important
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0!important
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0!important
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0!important
  }
  .p-sm-1 {
    padding: .5rem!important
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: .5rem!important
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: .5rem!important
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .5rem!important
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: .5rem!important
  }
  .p-sm-2 {
    padding: 1rem!important
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem!important
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem!important
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem!important
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem!important
  }
  .p-sm-3 {
    padding: 1.5rem!important
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem!important
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem!important
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem!important
  }
  .p-sm-4 {
    padding: 2rem!important
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem!important
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem!important
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem!important
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem!important
  }
  .p-sm-5 {
    padding: 2.5rem!important
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.5rem!important
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.5rem!important
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.5rem!important
  }
  .p-sm-6 {
    padding: 3rem!important
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem!important
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem!important
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem!important
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem!important
  }
  .p-sm-7 {
    padding: 3.5rem!important
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.5rem!important
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.5rem!important
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.5rem!important
  }
  .p-sm-8 {
    padding: 4rem!important
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem!important
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4rem!important
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem!important
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4rem!important
  }
  .p-sm-9 {
    padding: 4.5rem!important
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.5rem!important
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.5rem!important
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.5rem!important
  }
  .p-sm-10 {
    padding: 5rem!important
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem!important
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem!important
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem!important
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem!important
  }
  .p-sm-11 {
    padding: 5.5rem!important
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.5rem!important
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.5rem!important
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.5rem!important
  }
  .p-sm-12 {
    padding: 6rem!important
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6rem!important
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6rem!important
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6rem!important
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6rem!important
  }
  .m-sm-auto {
    margin: auto!important
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto!important
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto!important
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto!important
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto!important
  }
}
@media (min-width:768px) {
  .m-md-0 {
    margin: 0!important
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0!important
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0!important
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0!important
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0!important
  }
  .m-md-1 {
    margin: .5rem!important
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: .5rem!important
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: .5rem!important
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: .5rem!important
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: .5rem!important
  }
  .m-md-2 {
    margin: 1rem!important
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem!important
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem!important
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem!important
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem!important
  }
  .m-md-3 {
    margin: 1.5rem!important
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem!important
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem!important
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem!important
  }
  .m-md-4 {
    margin: 2rem!important
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem!important
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem!important
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem!important
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem!important
  }
  .m-md-5 {
    margin: 2.5rem!important
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.5rem!important
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.5rem!important
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.5rem!important
  }
  .m-md-6 {
    margin: 3rem!important
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem!important
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem!important
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem!important
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem!important
  }
  .m-md-7 {
    margin: 3.5rem!important
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.5rem!important
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.5rem!important
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.5rem!important
  }
  .m-md-8 {
    margin: 4rem!important
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem!important
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4rem!important
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem!important
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4rem!important
  }
  .m-md-9 {
    margin: 4.5rem!important
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.5rem!important
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.5rem!important
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.5rem!important
  }
  .m-md-10 {
    margin: 5rem!important
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem!important
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem!important
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem!important
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem!important
  }
  .m-md-11 {
    margin: 5.5rem!important
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.5rem!important
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.5rem!important
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.5rem!important
  }
  .m-md-12 {
    margin: 6rem!important
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6rem!important
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6rem!important
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6rem!important
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6rem!important
  }
  .p-md-0 {
    padding: 0!important
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0!important
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0!important
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0!important
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0!important
  }
  .p-md-1 {
    padding: .5rem!important
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: .5rem!important
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: .5rem!important
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: .5rem!important
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: .5rem!important
  }
  .p-md-2 {
    padding: 1rem!important
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem!important
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem!important
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem!important
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem!important
  }
  .p-md-3 {
    padding: 1.5rem!important
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem!important
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem!important
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem!important
  }
  .p-md-4 {
    padding: 2rem!important
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem!important
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem!important
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem!important
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem!important
  }
  .p-md-5 {
    padding: 2.5rem!important
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.5rem!important
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.5rem!important
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.5rem!important
  }
  .p-md-6 {
    padding: 3rem!important
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem!important
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem!important
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem!important
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem!important
  }
  .p-md-7 {
    padding: 3.5rem!important
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.5rem!important
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.5rem!important
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.5rem!important
  }
  .p-md-8 {
    padding: 4rem!important
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem!important
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4rem!important
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem!important
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 4rem!important
  }
  .p-md-9 {
    padding: 4.5rem!important
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.5rem!important
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.5rem!important
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.5rem!important
  }
  .p-md-10 {
    padding: 5rem!important
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem!important
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem!important
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem!important
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem!important
  }
  .p-md-11 {
    padding: 5.5rem!important
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.5rem!important
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.5rem!important
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.5rem!important
  }
  .p-md-12 {
    padding: 6rem!important
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6rem!important
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6rem!important
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6rem!important
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 6rem!important
  }
  .m-md-auto {
    margin: auto!important
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto!important
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto!important
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto!important
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto!important
  }
}
@media (min-width:992px) {
  .m-lg-0 {
    margin: 0!important
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0!important
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0!important
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0!important
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0!important
  }
  .m-lg-1 {
    margin: .5rem!important
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: .5rem!important
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .5rem!important
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .5rem!important
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .5rem!important
  }
  .m-lg-2 {
    margin: 1rem!important
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem!important
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem!important
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem!important
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem!important
  }
  .m-lg-3 {
    margin: 1.5rem!important
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem!important
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem!important
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem!important
  }
  .m-lg-4 {
    margin: 2rem!important
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem!important
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem!important
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem!important
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem!important
  }
  .m-lg-5 {
    margin: 2.5rem!important
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.5rem!important
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.5rem!important
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.5rem!important
  }
  .m-lg-6 {
    margin: 3rem!important
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem!important
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem!important
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem!important
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem!important
  }
  .m-lg-7 {
    margin: 3.5rem!important
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.5rem!important
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.5rem!important
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.5rem!important
  }
  .m-lg-8 {
    margin: 4rem!important
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem!important
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4rem!important
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem!important
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4rem!important
  }
  .m-lg-9 {
    margin: 4.5rem!important
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.5rem!important
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.5rem!important
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.5rem!important
  }
  .m-lg-10 {
    margin: 5rem!important
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem!important
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem!important
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem!important
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem!important
  }
  .m-lg-11 {
    margin: 5.5rem!important
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.5rem!important
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.5rem!important
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.5rem!important
  }
  .m-lg-12 {
    margin: 6rem!important
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6rem!important
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6rem!important
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6rem!important
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6rem!important
  }
  .p-lg-0 {
    padding: 0!important
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0!important
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0!important
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0!important
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0!important
  }
  .p-lg-1 {
    padding: .5rem!important
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: .5rem!important
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: .5rem!important
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .5rem!important
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: .5rem!important
  }
  .p-lg-2 {
    padding: 1rem!important
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem!important
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem!important
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem!important
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem!important
  }
  .p-lg-3 {
    padding: 1.5rem!important
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem!important
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem!important
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem!important
  }
  .p-lg-4 {
    padding: 2rem!important
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem!important
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem!important
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem!important
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem!important
  }
  .p-lg-5 {
    padding: 2.5rem!important
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.5rem!important
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.5rem!important
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.5rem!important
  }
  .p-lg-6 {
    padding: 3rem!important
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem!important
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem!important
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem!important
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem!important
  }
  .p-lg-7 {
    padding: 3.5rem!important
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.5rem!important
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.5rem!important
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.5rem!important
  }
  .p-lg-8 {
    padding: 4rem!important
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem!important
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4rem!important
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem!important
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4rem!important
  }
  .p-lg-9 {
    padding: 4.5rem!important
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.5rem!important
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.5rem!important
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.5rem!important
  }
  .p-lg-10 {
    padding: 5rem!important
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem!important
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem!important
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem!important
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem!important
  }
  .p-lg-11 {
    padding: 5.5rem!important
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.5rem!important
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.5rem!important
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.5rem!important
  }
  .p-lg-12 {
    padding: 6rem!important
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6rem!important
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6rem!important
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6rem!important
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6rem!important
  }
  .m-lg-auto {
    margin: auto!important
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto!important
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto!important
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto!important
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto!important
  }
}
.text-monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
}
.text-justify {
  text-align: justify!important
}
.text-nowrap {
  white-space: nowrap!important
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}
.text-left {
  text-align: left!important
}
.text-right {
  text-align: right!important
}
.text-center {
  text-align: center!important
}
@media (min-width:576px) {
  .text-sm-left {
    text-align: left!important
  }
  .text-sm-right {
    text-align: right!important
  }
  .text-sm-center {
    text-align: center!important
  }
}
@media (min-width:768px) {
  .text-md-left {
    text-align: left!important
  }
  .text-md-right {
    text-align: right!important
  }
  .text-md-center {
    text-align: center!important
  }
}
@media (min-width:992px) {
  .text-lg-left {
    text-align: left!important
  }
  .text-lg-right {
    text-align: right!important
  }
  .text-lg-center {
    text-align: center!important
  }
}
@media (min-width:1200px) {
  .m-xl-0 {
    margin: 0!important
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0!important
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0!important
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0!important
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0!important
  }
  .m-xl-1 {
    margin: .5rem!important
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: .5rem!important
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .5rem!important
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .5rem!important
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .5rem!important
  }
  .m-xl-2 {
    margin: 1rem!important
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem!important
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem!important
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem!important
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem!important
  }
  .m-xl-3 {
    margin: 1.5rem!important
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem!important
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem!important
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem!important
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem!important
  }
  .m-xl-4 {
    margin: 2rem!important
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem!important
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem!important
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem!important
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem!important
  }
  .m-xl-5 {
    margin: 2.5rem!important
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.5rem!important
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.5rem!important
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.5rem!important
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.5rem!important
  }
  .m-xl-6 {
    margin: 3rem!important
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem!important
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem!important
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem!important
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem!important
  }
  .m-xl-7 {
    margin: 3.5rem!important
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.5rem!important
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.5rem!important
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.5rem!important
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.5rem!important
  }
  .m-xl-8 {
    margin: 4rem!important
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem!important
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4rem!important
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem!important
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4rem!important
  }
  .m-xl-9 {
    margin: 4.5rem!important
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.5rem!important
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.5rem!important
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.5rem!important
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.5rem!important
  }
  .m-xl-10 {
    margin: 5rem!important
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem!important
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem!important
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem!important
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem!important
  }
  .m-xl-11 {
    margin: 5.5rem!important
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.5rem!important
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.5rem!important
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.5rem!important
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.5rem!important
  }
  .m-xl-12 {
    margin: 6rem!important
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6rem!important
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6rem!important
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6rem!important
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6rem!important
  }
  .p-xl-0 {
    padding: 0!important
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0!important
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0!important
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0!important
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0!important
  }
  .p-xl-1 {
    padding: .5rem!important
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: .5rem!important
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: .5rem!important
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .5rem!important
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: .5rem!important
  }
  .p-xl-2 {
    padding: 1rem!important
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem!important
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem!important
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem!important
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem!important
  }
  .p-xl-3 {
    padding: 1.5rem!important
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem!important
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem!important
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem!important
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem!important
  }
  .p-xl-4 {
    padding: 2rem!important
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem!important
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem!important
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem!important
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem!important
  }
  .p-xl-5 {
    padding: 2.5rem!important
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.5rem!important
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.5rem!important
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.5rem!important
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.5rem!important
  }
  .p-xl-6 {
    padding: 3rem!important
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem!important
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem!important
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem!important
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem!important
  }
  .p-xl-7 {
    padding: 3.5rem!important
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.5rem!important
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.5rem!important
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.5rem!important
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.5rem!important
  }
  .p-xl-8 {
    padding: 4rem!important
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem!important
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4rem!important
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem!important
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4rem!important
  }
  .p-xl-9 {
    padding: 4.5rem!important
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.5rem!important
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.5rem!important
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.5rem!important
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.5rem!important
  }
  .p-xl-10 {
    padding: 5rem!important
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem!important
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem!important
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem!important
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem!important
  }
  .p-xl-11 {
    padding: 5.5rem!important
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.5rem!important
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.5rem!important
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.5rem!important
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.5rem!important
  }
  .p-xl-12 {
    padding: 6rem!important
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6rem!important
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6rem!important
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6rem!important
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6rem!important
  }
  .m-xl-auto {
    margin: auto!important
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto!important
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto!important
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto!important
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto!important
  }
  .text-xl-left {
    text-align: left!important
  }
  .text-xl-right {
    text-align: right!important
  }
  .text-xl-center {
    text-align: center!important
  }
}
.text-lowercase {
  text-transform: lowercase!important
}
.text-uppercase {
  text-transform: uppercase!important
}
.text-capitalize {
  text-transform: capitalize!important
}
.font-weight-light {
  font-weight: 300!important
}
.font-weight-normal {
  font-weight: 400!important
}
.font-weight-bold {
  font-weight: 700!important
}
.font-italic {
  font-style: italic!important
}
.text-white {
  color: #fff!important
}
.text-primary {
  color: #007bff!important
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc!important
}
.text-secondary {
  color: #6c757d!important
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62!important
}
.text-success {
  color: #28a745!important
}
a.text-success:focus,
a.text-success:hover {
  color: #1e7e34!important
}
.text-info {
  color: #17a2b8!important
}
a.text-info:focus,
a.text-info:hover {
  color: #117a8b!important
}
.text-warning {
  color: #ffc107!important
}
a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00!important
}
.text-danger {
  color: #dc3545!important
}
a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130!important
}
.text-light {
  color: #f8f9fa!important
}
a.text-light:focus,
a.text-light:hover {
  color: #dae0e5!important
}
.text-dark {
  color: #343a40!important
}
a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124!important
}
.text-body {
  color: #212529!important
}
.text-muted {
  color: #6c757d!important
}
.text-black-50 {
  color: rgba(0,0,0,.5)!important
}
.text-white-50 {
  color: rgba(255,255,255,.5)!important
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}
@font-face {
  font-family: Brusher;
  src: local("Brusher"),local("Brusher-Regular"),url(../fonts/brusher/Brusher-Regular.woff2) format("woff2"),url(../fonts/brusher/Brusher-Regular.woff) format("woff"),url(../fonts/brusher/Brusher-Regular.ttf) format("truetype"),url(../fonts/brusher/Brusher-Regular.svg#Brusher-Regular) format("svg");
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: Storytella;
  src: local("Storytella"),url(../fonts/storytella/Storytella.woff2) format("woff2"),url(../fonts/storytella/Storytella.woff) format("woff"),url(../fonts/storytella/Storytella.ttf) format("truetype"),url(../fonts/storytella/Storytella.svg#Storytella) format("svg");
  font-weight: 400;
  font-style: normal
}
.slick-arrow {
  text-align: center;
  cursor: pointer;
  z-index: 1
}
.slick-dots {
  position: absolute;
  line-height: 0;
  font-size: 0
}
.slick-dots li {
  position: relative;
  display: inline-block;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 10px
}
.slick-dots li:first-child {
  margin-left: 0
}
.slick-dots li.slick-active button {
  cursor: default;
  background-color: #aeaeab;
  border-color: #aeaeab
}
.slick-dots button {
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  cursor: hand;
  color: transparent;
  border: 2px solid #5b5b5b;
  outline: 0;
  background: 0 0;
  border-radius: 50%;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,opacity .3s ease-in-out
}
.irs--round .irs-line {
  background-color: #f2f2f2
}
.irs--round .irs-bar {
  background-color: transparent
}
.irs--round .irs-handle {
  top: 30px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 2px solid #aeaeab;
  box-shadow: none
}
.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #fff
}
.irs--round .irs-from,
.irs--round .irs-single,
.irs--round .irs-to {
  background-color: #aeaeab
}
.irs--round .irs-from:before,
.irs--round .irs-single:before,
.irs--round .irs-to:before {
  border-top-color: #aeaeab
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden
}
#app main {
  flex: 1 0 auto
}
#app #footer {
  flex: 0 0 auto
}
iframe {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0
}
img {
  vertical-align: middle;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto
}
.lazy[src] {
  opacity: 0;
  will-change: opacity;
  transition: opacity .2s
}
@media screen and (prefers-reduced-motion:reduce) {
  .lazy[src],
  .slick-dots button {
    transition: none
  }
}
.lazy[src].loaded {
  opacity: 1
}
.section {
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 0
}
.section--no-pt {
  padding-top: .05px!important
}
.section--no-pb {
  padding-bottom: .05px!important
}
.section--base-bg {
  background-color: #aeaeab
}
.section--gray-bg {
  background-color: #f9f9f9
}
.section--dark-bg {
  background-color: #333
}
@media (min-width:768px) {
  .section {
    padding-top: 100px;
    padding-bottom: 100px
  }
  .section--gutter {
    margin-left: 20px;
    margin-right: 20px
  }
}
.desktop .jarallax {
  background-attachment: fixed
}
.circled {
  border-radius: 50%
}
.pagination {
  flex-wrap: wrap;
  align-items: center;
  margin: -5px;
  border-radius: 0
}
.pagination .page-item {
  margin: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none
}
.pagination .page-item:not(.active):hover .page-link {
  background-color: inherit;
  color: #4f4a37
}
.pagination .page-item.active .page-link {
  background-color: transparent;
  border-color: #aeaeab;
  color: #4f4a37;
  cursor: default
}
.pagination .page-item > span {
  display: inline-block;
  vertical-align: top;
  line-height: 1
}
.pagination .page-link {
  min-width: 36px;
  height: 36px;
  margin-left: 0;
  padding: 0;
  line-height: 31px;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Raleway,sans-serif;
  color: #a9a9a9;
  text-align: center;
  text-decoration: none;
  border: 2px solid #e9e9e9;
  border-radius: 50%!important;
  box-shadow: none;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
.simple-banner,
.simple-banner .__inner {
  position: relative
}
.simple-banner--style-1 {
  background-color: #f1f1f1
}
.simple-banner--style-1 .banner__text {
  padding-top: 85px;
  padding-bottom: 120px
}
.simple-banner--style-1 .__title {
  line-height: 1;
  text-transform: none
}
.simple-banner--style-1 .__title b {
  font-weight: inherit
}
.simple-banner--style-1 .__label {
  position: absolute;
  right: 25%;
  top: 12%;
  width: 185px;
  height: 185px;
  background-color: #aeaeab;
  border-radius: 50%;
  line-height: 1;
  font-size: 2.5rem;
  font-weight: 900;
  text-align: center;
  color: #bba243
}
.simple-banner--style-1 .__label span {
  display: block;
  text-align: left
}
.simple-banner--style-1 .__label .num-1 {
  line-height: .8;
  font-size: 8rem;
  color: #333
}
.simple-banner--style-1 .__label .num-2 {
  font-size: 4rem;
  color: #333
}
.simple-banner--style-2 .img-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2
}
.simple-banner--style-3 {
  background-color: #fbfbfb;
  background-image: url(../img/banner_bg_2.png);
  background-repeat: no-repeat;
  background-position: bottom 35% left 40%
}
.simple-banner--style-3 .banner__text {
  padding-top: 280px;
  padding-bottom: 140px
}
.simple-banner--style-3 .__title {
  line-height: .783;
  font-size: 12rem;
  font-family: Storytella;
  font-weight: 400;
  color: #ccaf8a;
  text-transform: none
}
.simple-banner--style-3 .__label {
  position: absolute;
  top: 7%;
  right: 26%
}
@media (min-width:576px) {
  .simple-banner--style-3 {
    background-position: bottom 35% right
  }
  .simple-banner--style-3 .banner__text {
    padding-top: 140px
  }
  .simple-banner--style-3 .__title {
    font-size: 18rem
  }
}
@media (min-width:768px) {
  .simple-banner--style-1 {
    background-image: url(../img/banner_bg_1.png);
    background-repeat: no-repeat;
    background-position: top right
  }
  .simple-banner--style-3 .__title {
    font-size: 23rem
  }
}
.tab-container {
  position: relative
}
.tab-container .tab-nav {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.tab-container .tab-nav a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.6rem;
  text-align: center;
  text-decoration: none!important;
  letter-spacing: 0;
  border: none;
  box-shadow: none;
  outline: 0;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  padding: 0 15px;
  transition: .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .pagination .page-link,
  .tab-container .tab-nav a {
    transition: none
  }
}
.tab-container .tab-content {
  position: relative
}
.tab-container .tab-content__item {
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out,visibility .3s ease-in-out
}
.tab-container .tab-content__item.is-visible {
  position: static;
  top: auto;
  left: auto;
  visibility: visible;
  z-index: 2;
  opacity: 1
}
.comments-list {
  margin-top: 30px;
  margin-bottom: 30px
}
.comments-list .comment {
  margin-top: 15px
}
.comments-list .comment__author-img {
  width: 70px;
  margin-right: 20px
}
.comments-list .comment__author-name,
.comments-list .comment__date-post {
  display: block;
  line-height: 1
}
.comments-list .comment__date-post {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #c1c1c1
}
.comments-list .comment__author-name {
  font-size: 1.6rem;
  font-family: Raleway,sans-serif;
  font-weight: 700;
  color: #333
}
.comments-list ul {
  padding-left: 30px
}
@media (min-width:992px) {
  .section {
    padding-top: 140px;
    padding-bottom: 140px
  }
  .comments-list ul {
    padding-left: 50px
  }
}
@media (min-width:1200px) {
  .comments-list ul {
    padding-left: 80px
  }
}
.widget {
  position: relative;
  margin-top: 40px
}
.widget:first-child {
  margin-top: 0
}
.widget .clear-filter {
  display: inline-block;
  background: 0 0;
  border: none;
  line-height: 1.2;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #4f4a37
}
.widget--text p {
  margin-top: 20px;
  margin-bottom: 20px
}
.widget--categories .list__item {
  margin-top: 10px;
  line-height: 1.2;
  padding-left: 15px
}
.widget--categories .list__item:before {
  content: "";
  float: left;
  width: 0;
  height: 0;
  margin-top: 7px;
  margin-left: -15px;
  margin-right: 10px;
  border: 2px solid #828282;
  border-right-width: 1px;
  border-bottom-width: 1px
}
.widget--categories .list__item:first-child {
  margin-top: 0
}
.widget--categories .list__item__link {
  display: inline-block;
  color: inherit
}
.widget--categories .list__item__link:focus,
.widget--categories .list__item__link:hover {
  color: #888
}
.widget--categories .list__item span {
  float: right
}
.widget--posts article {
  margin-top: 20px;
  line-height: 1.4
}
.widget--posts article:after {
  content: "";
  display: table;
  clear: both
}
.widget--posts article:first-child {
  margin-top: 0
}
.widget--posts .link {
  float: left;
  margin-right: 15px
}
.widget--posts .link img {
  width: 100px;
  height: 75px
}
.widget--posts .link + div {
  overflow: hidden
}
.widget--posts .date-post {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  line-height: 1.2;
  font-size: 1.2rem;
  color: #c1c1c1
}
.widget--tags ul {
  margin: -3px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.widget--tags li {
  display: inline-block;
  vertical-align: top;
  margin: 3px
}
.widget--tags a {
  display: inline-block;
  padding: 8px 20px;
  background-color: #333;
  line-height: 1.2;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0;
  color: #fff;
  border-radius: 30px;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: background-color .25s ease-in-out,border-color .25s ease-in-out,color .25s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .tab-container .tab-content__item,
  .widget--tags a {
    transition: none
  }
}
.widget--tags a:focus,
.widget--tags a:hover {
  background-color: #aeaeab;
  color: #333
}
.widget--price .widget-title + div {
  margin-top: -25px
}
.widget--price .range-slider-max-value,
.widget--price .range-slider-min-value {
  display: block;
  width: 100%;
  margin-top: 5px;
  border: none;
  box-shadow: none;
  outline: 0;
  pointer-events: none;
  user-select: none
}
.widget--price .range-slider-max-value {
  text-align: right
}
.widget--additional ul {
  line-height: 1
}
.widget--additional li {
  margin-top: 20px
}
.widget--additional .checkfield,
.widget--additional li:first-child {
  margin-top: 0
}
.widget--products ul {
  line-height: 1
}
.widget--products li {
  margin-top: 10px
}
.widget--products li:first-child {
  margin-top: 0
}
.widget--products .__image-wrap {
  width: 45%;
  max-width: 120px;
  padding-right: 30px
}
.widget--products .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  padding-top: 100%;
  overflow: hidden
}
.widget--products .__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 95%
}
.widget--products .__title {
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: none
}
.widget--products .rating__item {
  font-size: 1.1rem
}
.widget--products .product-price {
  margin-top: 10px;
  margin-left: -10px
}
.widget--products .product-price__item {
  margin-left: 10px
}
.counter .__inner {
  margin-bottom: -30px
}
.counter .__item {
  width: 100%;
  margin-bottom: 30px
}
.counter .__item p {
  margin-top: 10px;
  margin-bottom: 10px
}
.counter .__item .__ico {
  display: block;
  width: 85px;
  margin-right: 15px
}
.counter .__item .__count {
  line-height: 1;
  font-size: 5rem;
  font-weight: 900;
  color: #333
}
.counter .__item .__title {
  line-height: 1.2;
  font-size: 1.6rem;
  font-weight: 700;
  font-family: Raleway,sans-serif;
  color: #555
}
[class$=base-bg] .counter .__item .__title,
[class*=base-bg] .counter .__item .__title {
  color: #9a8841
}
[class$=gray-bg] .counter .__item .__title,
[class*=gray-bg] .counter .__item .__title {
  color: #858585
}
.social-btns {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px;
  margin: 0 -5px
}
.social-btns a {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: #fff;
  text-align: center!important;
  text-decoration: none!important;
  letter-spacing: 0;
  margin: 0 5px;
  transition: background-color .3s ease-in-out,border-color .3s ease-in-out,color .3s ease-in-out
}
.social-btns a:before {
  line-height: inherit
}
.social-btns a i {
  display: inline-block;
  width: 1em
}
#btn-to-top-wrap {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 3
}
@media only screen and (min-width:561px) {
  #btn-to-top-wrap {
    right: 25px;
    bottom: 25px
  }
}
#btn-to-top {
  display: block;
  width: 44px;
  height: 44px;
  line-height: 42px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #fcd641;
  opacity: .7;
  transition: opacity .3s ease-in-out
}
#btn-to-top:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-bottom: 6px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent
}
#btn-to-top:hover {
  opacity: 1
}
.page-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -30px
}
.page-nav__item {
  margin-bottom: 30px
}
.page-nav__link {
  display: block;
  line-height: 1;
  font-size: 1.8rem;
  font-weight: 700;
  font-family: Raleway,sans-serif;
  color: #b3b3b3!important
}
.page-nav__link--prev .ico {
  margin-right: 15px
}
.page-nav__link--next {
  text-align: right
}
.page-nav__link--next .ico {
  margin-left: 15px
}
.page-nav__link .ico {
  color: #ccc
}
.product-label {
  position: absolute;
  top: 5px;
  left: 5px;
  min-width: 60px;
  padding: 9px 15px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  user-select: none
}
.product-label--new {
  background-color: #4bbe60
}
.product-label--hot {
  background-color: #eb9f34
}
.product-label--sale {
  background-color: #f26666
}
.product-price {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.product-price__item {
  display: inline-block;
  line-height: 1;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 0;
  color: #333
}
.product-price__item--old {
  font-size: 1.4rem;
  text-decoration: line-through;
  color: #ea0d0d
}
.quantity-counter {
  position: relative;
  width: 95px
}
.quantity-counter .__q-input {
  display: block;
  float: none;
  width: 100%;
  height: 24px;
  background: padding-box none;
  border: none;
  line-height: 1.2;
  font-size: 1.6rem;
  text-align: center;
  color: #494949;
  appearance: none;
  outline: 0;
  padding: 0 30px;
  box-shadow: none;
  border-radius: 0
}
.quantity-counter .__btn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #ddd;
  line-height: 20px;
  font-size: 2.8rem;
  text-align: center;
  color: #494949;
  cursor: pointer;
  opacity: .3;
  border-radius: 50%;
  user-select: none;
  transition: opacity .3s ease-in-out
}
.quantity-counter .__btn:hover {
  opacity: 1
}
.quantity-counter .__btn:after,
.quantity-counter .__btn:before {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: currentColor
}
.quantity-counter .__btn--minus {
  left: 0
}
.quantity-counter .__btn--minus:before {
  content: "";
  margin-top: -1px;
  margin-left: -5px;
  width: 10px;
  height: 2px
}
.quantity-counter .__btn--plus {
  right: 0
}
.quantity-counter .__btn--plus:before {
  content: "";
  margin-top: -1px;
  margin-left: -5px;
  width: 10px;
  height: 2px
}
.quantity-counter .__btn--plus:after {
  content: "";
  margin-top: -5px;
  margin-left: -1px;
  width: 2px;
  height: 10px
}
.rating {
  border: none;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.rating__item {
  display: inline-block;
  vertical-align: top;
  margin-left: .4em;
  line-height: 1;
  font-size: 1.4rem;
  color: #ccc;
  letter-spacing: 0;
  user-select: none;
  transition: color .3s ease-in-out
}
.rating__item:first-child {
  margin-left: 0
}
.rating__item--active {
  color: #fac917
}
.rating__item i {
  display: block
}
.rating input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  clip: rect(2px,2px,2px,2px)
}
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #fac917
}
.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffd778
}
.feature .__inner {
  margin-bottom: -50px
}
.feature .__item {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  z-index: 1
}
.feature .__item .__ico {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px
}
.feature .__item .__ico img {
  max-height: 100%
}
.feature .__item .__title {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: none
}
.feature--style-1 .__item:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  bottom: -15px;
  border: 5px solid transparent;
  margin-left: -25px;
  padding: 0 20px;
  z-index: -1;
  transition: border-color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  #btn-to-top,
  .feature--style-1 .__item:before,
  .quantity-counter .__btn,
  .rating__item,
  .social-btns a {
    transition: none
  }
}
.feature--style-1 .__item .__ico {
  height: 65px
}
.feature--style-1 .__item .__title {
  font-size: 1.4rem
}
.feature--style-1 .__item:hover:before {
  border-color: #aeaeab
}
.feature--style-2 .__item {
  padding-left: 85px
}
.feature--style-2 .__item:after {
  content: "";
  display: table;
  clear: both;
  visibility: hidden
}
.feature--style-2 .__item .__ico {
  float: left;
  width: 70px;
  margin-left: -85px
}
.feature--style-2 .__item p {
  margin-top: 10px;
  margin-bottom: 10px
}
.feature--style-3 .__item .__ico {
  height: 80px
}
.feature--style-3 .__item .__title {
  font-size: 1.4rem
}
.product-preview {
  width: 100%
}
.product-preview .__inner {
  margin-bottom: -10px
}
.product-preview .row {
  margin-left: -5px;
  margin-right: -5px
}
.product-preview .col,
.product-preview [class*=col-] {
  padding-left: 5px;
  padding-right: 5px
}
.product-preview .__item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  transition: padding .5s
}
.product-preview .__item .__intro-text {
  margin-left: auto;
  width: 100%;
  max-width: 580px
}
.product-preview .__item .__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  transition: top .5s,right .5s,bottom .5s,left .5s
}
.product-preview .__item .__image + .__content {
  background-color: rgba(51,51,51,.2)
}
.product-preview .__item .__image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
@media screen and (prefers-reduced-motion:reduce) {
  .product-preview .__item,
  .product-preview .__item .__image,
  .product-preview .__item .__image img {
    transition: none
  }
}
.product-preview .__item .__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #333;
  color: #fff;
  transition: background-color .3s ease-in-out
}
.product-preview .__item .__title {
  color: inherit
}
.product-preview .__item .__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.product-preview .__item:hover .__image + .__content {
  background-color: rgba(74,96,153,.9)
}
.product-preview--style-1 .__item {
  height: 325px
}
.product-preview--style-2 .__item {
  height: 450px
}
.product-preview--style-2 .__item:hover {
  padding: 20px
}
.product-preview--style-2 .__item:hover .__image {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px
}
@media (min-width:768px) {
  .product-preview--style-2 .__item {
    height: 550px
  }
}
@media (min-width:992px) {
  .product-preview--style-2 .__item {
    height: 650px
  }
}
.product-promo {
  width: 100%;
  margin-left: auto;
  margin-right: auto
}
.product-promo .__item {
  position: relative
}
.product-promo .__item:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.product-promo .__image {
  position: relative;
  margin: auto;
  overflow: hidden
}
.product-promo .__image img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
.product-promo .__content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px
}
.product-promo .__title {
  color: inherit
}
.product-promo .__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
.product-promo--style-1 .__inner {
  margin-bottom: -30px
}
.product-promo--style-1 .__item {
  margin-bottom: 30px
}
.product-promo--style-1 .__content {
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: background-color .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .product-preview .__item .__content,
  .product-promo .__image img,
  .product-promo--style-1 .__content {
    transition: none
  }
}
.product-promo--style-2 {
  max-width: 1380px;
  margin-top: -120px
}
.product-promo--style-2 .__item {
  width: 100%;
  min-height: 320px
}
.product-promo--style-2 .__item[data-y="2"] .__image {
  height: 640px
}
.product-promo--style-2 .__image {
  width: 100%;
  height: 320px
}
.product-promo--style-2 .__image:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 35%;
  min-height: 200px;
  background: -moz-linear-gradient(90deg,rgba(0,0,0,.75) 0,rgba(255,255,255,0) 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(255,255,255,0)),color-stop(100%,rgba(0,0,0,.75)));
  background: -webkit-linear-gradient(90deg,rgba(0,0,0,.75) 0,rgba(255,255,255,0) 100%);
  background: -o-linear-gradient(90deg,rgba(0,0,0,.75) 0,rgba(255,255,255,0) 100%);
  background: -ms-linear-gradient(90deg,rgba(0,0,0,.75) 0,rgba(255,255,255,0) 100%);
  background: linear-gradient(0deg,rgba(0,0,0,.75) 0,rgba(255,255,255,0) 100%)
}
.product-promo--style-2 .__image + .__content {
  align-items: flex-end;
  justify-content: flex-start;
  color: #fff
}
.product-promo--style-2 .__content {
  align-items: center;
  justify-content: center;
  color: #aeaeab
}
@media (min-width:768px) {
  .product-promo--style-1 {
    margin-top: -260px
  }
  .product-promo--style-1 .__item--first {
    margin-top: 10%;
    margin-right: 10%;
    z-index: 1
  }
  .product-promo--style-1 .__item--first .__content:before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: -15%;
    right: -10%;
    width: 65%;
    height: 105%;
    border: 10px solid #aeaeab;
    z-index: -1
  }
  .product-promo--style-1 .__item--second {
    margin-left: -35%
  }
  .product-promo--style-2 {
    margin-top: -190px
  }
}
@media (min-width:992px) {
  .product-promo--style-1 {
    margin-top: -300px
  }
  .product-promo--style-1 .__item--second {
    margin-left: -45%
  }
  .product-promo--style-2 {
    margin-top: -250px
  }
}
.product-features {
  counter-reset: num
}
.product-features .__item {
  position: relative;
  width: 100%;
  margin-top: 40px;
  z-index: 1
}
.product-features .__item:first-child {
  margin-top: 0
}
.product-features .__item .__inner {
  width: 100%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto
}
.product-features .__image {
  margin-top: 30px
}
.product-features .__content {
  margin: auto
}
.product-features .__content > div {
  position: relative
}
.product-features .__content > div:before {
  position: absolute;
  left: 0;
  top: -55px;
  line-height: 1;
  font-size: 23rem;
  font-weight: 900;
  font-family: Raleway,sans-serif;
  color: rgba(239,239,239,.7);
  counter-increment: num;
  content: counter(num,decimal-leading-zero);
  z-index: -1
}
.product-features .__content ol {
  list-style: decimal inside
}
@media (min-width:992px) {
  .product-features .__item {
    margin-top: 70px
  }
  .product-features .__item:nth-of-type(odd) .__content {
    margin-left: 10%
  }
  .product-features .__item:nth-of-type(even) .__inner > .row {
    flex-direction: row-reverse
  }
  .product-features .__item:nth-of-type(even) .__content {
    margin-right: 10%
  }
  .product-features .__image {
    margin-top: 0
  }
  .product-features .__content {
    max-width: 470px
  }
}
@media (min-width:1200px) {
  .product-features .__item {
    margin-top: 100px
  }
  .product-features .__item:nth-of-type(odd) .__content {
    margin-left: 15%
  }
  .product-features .__item:nth-of-type(even) .__content {
    margin-right: 15%
  }
}
.products-list .__item {
  position: relative;
  width: 100%;
  margin-top: 60px;
  z-index: 1
}
.products-list .__item:first-child {
  margin-top: 0
}
.products-list .__item .__bg {
  display: none
}
.products-list .__item .__inner {
  width: 100%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto
}
.products-list .__content {
  margin: auto
}
@media (min-width:576px) {
  .products-list .__item {
    margin-top: 30px
  }
}
@media (min-width:768px) {
  .products-list .__item:nth-of-type(odd) .__bg {
    right: -15px
  }
  .products-list .__item:nth-of-type(even) .__bg {
    left: -15px
  }
  .products-list .__item .__bg {
    display: block;
    position: absolute;
    bottom: 5%;
    z-index: -1
  }
}
@media (min-width:992px) {
  .products-list .__item:nth-of-type(odd) .__content {
    margin-left: 10%
  }
  .products-list .__item:nth-of-type(even) .__inner > .row {
    flex-direction: row-reverse
  }
  .products-list .__item:nth-of-type(even) .__content {
    margin-right: 10%
  }
  .products-list .__item .__bg {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%)
  }
  .products-list .__content {
    max-width: 470px
  }
}
.partners-list .slick-dots {
  position: relative;
  margin-top: 20px;
  text-align: center
}
.section--review {
  background-repeat: no-repeat;
  background-position: 50% 50%
}
.review__item {
  line-height: 1.2
}
.review__item__text {
  font-size: 2rem;
  font-family: "Times New Roman";
  color: #696248
}
.section--dark-bg .review__item__text {
  color: #fff
}
.review__item__text p {
  margin-top: 20px;
  margin-bottom: 20px
}
.review__item__author {
  width: auto;
  margin-top: 30px;
  font-size: 1.8rem;
  font-family: Raleway,sans-serif
}
.review__item__author-image {
  margin-right: 20px
}
.review__item__author-name {
  color: #333
}
.section--dark-bg .review__item__author-name {
  color: #fff
}
.review__item__author-position {
  display: block;
  color: #adadad
}
.section--dark-bg .review__item__author-position {
  color: #fff
}
.review--slider .slick-slider {
  overflow-x: hidden
}
.review--slider .slick-list {
  margin-left: -15px;
  margin-right: -15px
}
.review--slider .slick-slide {
  padding-left: 15px;
  padding-right: 15px
}
.review--slider .slick-dots {
  bottom: 28px;
  right: 0
}
@media (min-width:576px) {
  .review {
    margin-left: 60px
  }
  .review:before {
    float: left;
    margin-top: -15px;
    margin-left: -60px;
    content: "“";
    line-height: .8;
    font-size: 10rem;
    font-family: "Times New Roman";
    font-weight: 700;
    color: #aeaeab
  }
  .review__item__author-position {
    display: inline
  }
}
@media (min-width:768px) {
  .review {
    margin-left: 90px
  }
  .review:before {
    margin-left: -90px
  }
  .review__item__text {
    font-size: 2.5rem
  }
}
@media (min-width:1200px) {
  .products-list .__item:nth-of-type(odd) .__content {
    margin-left: 15%
  }
  .products-list .__item:nth-of-type(even) .__content {
    margin-right: 15%
  }
  .review {
    margin-right: 90px
  }
}
.recipes {
  margin-top: -55px
}
.recipes .__item {
  position: relative;
  width: 100%;
  background-color: #fff
}
.recipes .__item:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.recipes .__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 210px;
  padding-top: 81.03448%;
  margin: 0
}
.recipes .__image img {
  transform: translateZ(0);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
.recipes .__content {
  position: relative;
  width: 100%;
  padding: 20px 15px;
  font-size: 1.4rem;
  color: #666
}
.recipes .__title {
  font-weight: 700;
  text-transform: none
}
@media (min-width:576px) {
  .recipes .__content {
    padding: 30px
  }
}
.content-container ol:not([class]),
.content-container ul:not([class]) {
  line-height: 1.2;
  margin-top: 25px;
  margin-bottom: 25px
}
.content-container ol:not([class]):first-child,
.content-container ul:not([class]):first-child {
  margin-top: 0!important
}
.content-container ol:not([class]):last-child,
.content-container ul:not([class]):last-child {
  margin-bottom: 0!important
}
.content-container ol:not([class]) li,
.content-container ul:not([class]) li {
  margin-top: 5px;
  padding-left: 15px
}
.content-container ol:not([class]) li:before,
.content-container ul:not([class]) li:before {
  float: left;
  margin-left: -15px;
  margin-right: 5px
}
.content-container ol:not([class]) li:first-child,
.content-container ul:not([class]) li:first-child {
  margin-top: 0
}
.content-container ol:not([class]) {
  counter-reset: num
}
.content-container ol:not([class]) li:before {
  counter-increment: num;
  content: counter(num) "."
}
.content-container ul:not([class]) li:before {
  content: "";
  width: 0;
  height: 0;
  margin-top: 6px;
  border: 2px solid #555
}
.content-container hr {
  margin-top: 60px;
  margin-bottom: 60px;
  border: none;
  border-top: 1px solid #ebebeb
}
.content-container hr:first-child {
  margin-top: 0!important
}
.content-container hr:last-child {
  margin-bottom: 0!important
}
.content-container .blockquot {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 20px;
  border-left: 5px solid #aeaeab;
  line-height: 1.2;
  font-size: 2rem;
  font-family: 'Times New Roman',Times,serif;
  color: #696248
}
.content-container .blockquot:first-child {
  margin-top: 0
}
.content-container .blockquot:last-child {
  margin-bottom: 0
}
@media (min-width:576px) {
  .content-container .blockquot {
    font-size: 2.3rem
  }
}
.content-container .dropcaps .first-letter {
  float: left;
  margin-right: 10px;
  line-height: .9;
  font-size: 4.6rem;
  font-weight: 800;
  color: #aeaeab
}
.section--custom-02 {
  background-image: url(../img/section_02_bg.png);
  background-repeat: no-repeat;
  background-position: center bottom -5%
}
@media (min-width:768px) {
  .recipes {
    margin-top: -110px
  }
  .content-container .blockquot {
    padding-left: 25px;
    font-size: 2.5rem
  }
  .section--custom-02 {
    background-position: 114% 150%
  }
}
.posts .__inner {
  margin-bottom: -30px
}
.posts .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px
}
.posts .__item .__image {
  position: relative;
  width: 100%;
  height: 0;
  margin: auto;
  overflow: hidden
}
.posts .__item .__image img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
.posts .__item .__image .__content {
  background-color: transparent;
  z-index: 2
}
.posts .__item .__content {
  position: relative;
  padding-top: 40px;
  line-height: 1.6
}
.posts .__item .__category {
  margin-bottom: 10px;
  line-height: 1.2;
  font-size: 1.2rem;
  color: #ddc051
}
.posts .__item .__category a {
  color: inherit
}
.posts .__item .__title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: none
}
.posts .__item .custom-btn {
  margin-top: 10px
}
.posts .__item .__date-post {
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #c1c1c1
}
.posts .__item:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.posts .__item--preview .__date-post {
  display: inline-block;
  background-color: #aeaeab;
  min-width: 60px;
  padding: 13px 10px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  color: #9a8841
}
.posts .__item--preview .__date-post strong {
  display: block;
  margin-bottom: 2px;
  font-size: 2.5rem;
  font-weight: 900;
  font-family: Raleway,sans-serif;
  color: #333
}
.posts .__item--preview p {
  margin-top: 10px;
  margin-bottom: 10px
}
.posts .__item--preview p:first-child {
  margin-top: 0
}
.posts .__item--preview p:last-child {
  margin-bottom: 0
}
.posts-feedback {
  margin-top: 60px
}
.posts-feedback .textfield:focus,
.posts-feedback .textfield:hover {
  border-bottom-color: #333
}
.post-author {
  font-size: 1.4rem;
  color: #b3b3b3
}
.post-author__img {
  width: 70px;
  margin-right: 20px
}
.post-author__name {
  display: block;
  font-size: 1.6rem;
  font-family: Raleway,sans-serif;
  font-weight: 700;
  color: #333
}
.post-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -30px
}
.post-nav__item {
  margin-bottom: 30px
}
.post-nav__link {
  display: block;
  line-height: 1;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #b3b3b3!important
}
.post-nav__link--prev .ico {
  margin-right: 30px
}
.post-nav__link--next {
  text-align: right
}
.post-nav__link--next .ico {
  margin-left: 30px
}
.post-nav__link .ico {
  display: block;
  font-size: 3rem;
  color: #aeaeab
}
.post-nav__link .name {
  display: block;
  margin-top: 10px;
  font-size: 1.8rem;
  font-family: Raleway,sans-serif;
  font-weight: 700;
  text-transform: none;
  color: #333
}
.share-btns ul {
  margin: -5px;
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px
}
.share-btns li {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.1;
  margin: 5px
}
.share-btns a {
  display: block;
  padding: 8px 15px;
  font-size: 1.1rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0;
  border-radius: 15px;
  transition: background-color .25s ease-in-out,border-color .25s ease-in-out,color .25s ease-in-out
}
.share-btns .fb {
  background-color: #415993
}
.share-btns .fb:focus,
.share-btns .fb:hover {
  background-color: #394e81
}
.share-btns .tw {
  background-color: #4babe7
}
.share-btns .tw:focus,
.share-btns .tw:hover {
  background-color: #35a1e4
}
.share-btns .ggl {
  background-color: #f34a38
}
.share-btns .ggl:focus,
.share-btns .ggl:hover {
  background-color: #f23420
}
.share-btns .pt {
  background-color: #c9353d
}
.share-btns .pt:focus,
.share-btns .pt:hover {
  background-color: #b53037
}
.share-btns [class*=" fontello-"],
.share-btns [class^=fontello-] {
  margin-right: 5px;
  font-size: 1.3rem
}
.posts--style-1 .__item--preview .__image {
  padding-top: 69.72973%
}
.posts--style-1 .__item--preview .__content {
  background-color: #fff;
  text-align: center;
  transition: transform .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .posts .__item .__image img,
  .posts--style-1 .__item--preview .__content,
  .recipes .__image img,
  .share-btns a {
    transition: none
  }
}
.posts--style-1 .__item--preview .__date-post {
  position: absolute;
  top: 0;
  left: 20px
}
.desktop .posts--style-1 .__item--preview:hover .__content {
  transform: translateY(-40px)
}
.posts--style-2 .__item--preview .__image {
  padding-top: 106.75676%
}
.posts--style-2 .__item--preview .__image .__content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: 15px
}
.posts--style-2 .__item--preview .__overlay {
  background-color: rgba(11,11,11,.45)
}
.posts--style-2 .__item--preview .__content {
  padding: 30px 15px 0
}
.posts--style-2 .__item--preview .__title {
  color: #fff
}
.posts--style-2 .__item--preview .__date-post:first-child {
  margin-bottom: 15px
}
@media (min-width:576px) {
  .posts--style-1 .__item--preview .__content {
    padding-left: 15px;
    padding-right: 15px
  }
  .posts--style-2 {
    padding-left: 30px;
    padding-right: 30px
  }
}
@media (min-width:992px) {
  .section--custom-01 {
    background-image: url(../img/section_01_bg.png);
    background-repeat: no-repeat;
    background-position: right bottom
  }
  .section--custom-03 {
    background-image: url(../img/section_03_bg.png);
    background-repeat: no-repeat;
    background-position: right top 10%
  }
  .posts-feedback {
    margin-top: 90px
  }
}
.company-contacts {
  margin-top: 30px;
  margin-bottom: 30px
}
.company-contacts:first-child {
  margin-top: 0
}
.company-contacts:last-child {
  margin-bottom: 0
}
.company-contacts .__inner {
  margin-bottom: -30px
}
.company-contacts .__item {
  margin-bottom: 30px;
  line-height: 1.4;
  font-size: 1.6rem
}
.company-contacts .__ico {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  font-size: 4.5rem;
  color: #aeaeab
}
.company-contacts a {
  text-decoration: underline;
  color: inherit
}
.company-contacts a:focus,
.company-contacts a:hover {
  text-decoration: none
}
.section--contacts .map-container {
  margin-top: 70px;
  margin-bottom: -70px
}
.section--contacts .map-container .g_map {
  height: 100%;
  width: 100%
}
.section--contacts .map-container--left {
  left: 0
}
.section--contacts .map-container--right {
  right: 0
}
.contact-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 770px
}
.section--dark-bg .contact-form .textfield:focus,
.section--dark-bg .contact-form .textfield:hover {
  color: #aeaeab
}
@media (min-width:768px) {
  .posts--style-1 .__item--preview .__content {
    margin: -40px 20px 0;
    padding-left: 20px;
    padding-right: 20px
  }
  .section--contacts .map-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0
  }
}
#footer {
  position: relative
}
#footer .footer__item {
  width: 100%;
  margin-bottom: 40px
}
#footer .footer__item__title {
  font-weight: 700
}
#footer address {
  line-height: 1.6;
  font-style: normal
}
#footer address p {
  margin-top: 10px;
  margin-bottom: 10px
}
#footer address p:first-child {
  margin-top: 0!important
}
#footer address p:last-child {
  margin-bottom: 0!important
}
#footer address a[href*=mailto] {
  text-decoration: underline
}
#footer address a[href*=mailto]:focus,
#footer address a[href*=mailto]:hover {
  text-decoration: none
}
#footer__navigation:first-child {
  margin-top: -10px
}
#footer__navigation li {
  margin-top: 10px
}
#footer .__contact {
  margin-top: 15px;
  margin-bottom: 15px
}
#footer .__contact .__title {
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: #000;
  margin-bottom: 15px
}
#footer .__contact .b-table .cell + .cell {
  width: 100%
}
#footer .__contact .__ico {
  display: inline-block;
  line-height: 1;
  font-size: 1.6rem;
  margin-right: 10px
}
#footer .__contact .__ico:before {
  vertical-align: top
}
#footer .social-btns a {
  font-size: 2rem
}
#footer .social-btns a.active,
#footer .social-btns a:hover {
  color: #aeaeab
}
#footer .__copy {
  font-size: 1.3rem
}
#footer .__dev {
  color: #4a8b71
}
#footer .__dev:focus,
#footer .__dev:hover {
  text-decoration: underline
}
.footer--style-1 {
  padding-top: 60px;
  padding-bottom: 20px;
  font-size: 1.3rem
}
.footer--style-1 address a {
  color: inherit
}
.footer--style-1 .social-btns a {
  color: #ccc
}
.footer--style-1 #footer__navigation ul {
  margin-left: -35px
}
.footer--style-1 #footer__navigation li {
  margin-left: 35px
}
.footer--style-1 #footer__navigation li.active a,
.footer--style-1 #footer__navigation li:hover a {
  color: #c2c2c2
}
.footer--style-1 #footer__navigation a {
  color: inherit
}
.footer--style-1 .__copy {
  color: #b4b4b4
}
.footer--style-2 {
  padding-top: 75px;
  background-color: #2a2a2a
}
.footer--style-2 .footer__item__title {
  color: #fff
}
.footer--style-2 address {
  color: #c4c4c4
}
.footer--style-2 address a {
  color: #aeaeab
}
.footer--style-2 .social-btns {
  margin-top: 15px
}
.footer--style-2 .social-btns a {
  color: #535353
}
.footer--style-2 form:not(.form--horizontal) button[type=submit] {
  margin-top: 20px
}
.footer--style-2 #footer__navigation li {
  display: block;
  font-size: 1.4rem;
  text-transform: none
}
.footer--style-2 #footer__navigation li.active a,
.footer--style-2 #footer__navigation li:hover a {
  color: #fff
}
.footer--style-2 #footer__navigation a {
  color: #aaa
}
.footer--style-2 .copyright {
  padding-top: 30px;
  border-top: 1px solid #464646
}
.footer--style-2 .__copy {
  color: #7e7e7e
}
.footer--style-2 .__copy a {
  color: inherit
}
.footer--style-3 {
  padding-top: 75px;
  background-color: #2a2a2a
}
.footer--style-3 #footer__navigation li {
  display: block;
  font-size: 1.4rem;
  text-transform: none
}
.footer--style-3 #footer__navigation li.active a,
.footer--style-3 #footer__navigation li:hover a {
  color: #fff
}
.footer--style-3 #footer__navigation a {
  color: #aaa
}
.footer--style-3 address {
  color: #fff
}
.footer--style-3 address .__title {
  font-size: 1.2rem;
  color: #aeaeab
}
.footer--style-3 address a {
  color: inherit
}
.footer--style-3 .social-btns a {
  color: #535353
}
.footer--style-3 .copyright {
  padding-top: 30px;
  border-top: 1px solid #464646
}
.footer--style-3 .__copy {
  color: #7e7e7e
}
.footer--style-3 .__copy a {
  color: inherit
}
.footer--style-3 .g_map {
  height: 100%
}
.footer--style-4 {
  padding-top: 75px;
  background-color: #333
}
@media (min-width:576px) {
  .footer--style-4 {
    padding-top: 110px
  }
}
.footer--style-4 .footer__item__title {
  color: #fff
}
.footer--style-4 address {
  color: #c4c4c4
}
.footer--style-4 address a {
  color: #aeaeab
}
.footer--style-4 .social-btns {
  margin-top: 15px
}
.footer--style-4 .social-btns a {
  color: #535353
}
.footer--style-4 #footer__navigation {
  margin-top: 0
}
.footer--style-4 #footer__navigation li {
  display: block;
  font-size: 1.4rem;
  text-transform: none
}
.footer--style-4 #footer__navigation li.active a,
.footer--style-4 #footer__navigation li:hover a {
  color: #fff
}
.footer--style-4 #footer__navigation a {
  color: #aaa
}
@media (max-width:575.98px) {
  .footer--style-4 #footer__navigation ul {
    margin-bottom: 30px
  }
  .footer--style-4 .form--horizontal .input-wrp {
    padding-right: 0;
    margin-bottom: 20px
  }
}
.footer--style-4 .copyright {
  padding-top: 40px;
  border-top: 1px solid #464646
}
.footer--style-4 .__copy {
  color: #7e7e7e
}
.footer--style-4 .__copy a {
  color: inherit
}
@media (min-width:768px) {
  .footer--style-1 .site-logo {
    margin-top: -30px
  }
  .footer--style-3 .map-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 42%;
    height: 100%
  }
}
.sidebar {
  position: relative;
  transform: translateZ(0);
  z-index: 3
}
.timeline {
  margin-top: 80px
}
.timeline .__inner {
  margin-bottom: -30px
}
.timeline .col:last-child .__item:before,
.timeline [class*=col-]:last-child .__item:before {
  content: none
}
.timeline .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 30px
}
.timeline .__item:before {
  content: "";
  position: absolute;
  top: 25px;
  left: 11px;
  bottom: -27px;
  border-left: 1px solid #dfc356
}
.section--base-bg .timeline .__item {
  color: #9a8841
}
.timeline p {
  margin-top: 20px;
  margin-bottom: 20px
}
.timeline .__ico {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: 2px solid #c6ad4d;
  padding: 3px;
  border-radius: 50%
}
.timeline .__ico:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #c6ad4d;
  border-radius: inherit
}
.timeline .__year {
  line-height: 1;
  font-size: 5rem;
  font-weight: 900;
  color: #333
}
.timeline .__title {
  text-transform: none;
  font-weight: 700
}
@media (min-width:768px) {
  .timeline .__item {
    padding-top: 80px;
    padding-left: 0
  }
  .timeline .__item:before {
    top: 0;
    left: 25px;
    right: -27px;
    bottom: auto;
    border-top: 1px solid #dfc356;
    border-left: none
  }
  .timeline .__ico {
    margin-top: -11px
  }
}
.team .__inner {
  margin-bottom: -30px
}
.team .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px
}
.team .__item .__image {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 89.18919%;
  margin: auto;
  overflow: hidden
}
.team .__item .__image img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
.team .__item .__content {
  position: relative;
  margin: -40px 20px 0;
  padding: 30px 20px 20px;
  background-color: #fff;
  line-height: 1;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #ddc051;
  transition: transform .3s ease-in-out
}
.team .__item .__title {
  font-weight: 700;
  text-transform: none;
  margin-bottom: 10px
}
.team .__item:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.desktop .team .__item:hover .__content {
  transform: translateY(-40px)
}
#gallery-set {
  line-height: 0;
  font-size: 0;
  letter-spacing: -1px;
  margin-left: -10px;
  margin-bottom: 35px
}
#gallery-set a,
#gallery-set li {
  display: inline-block;
  vertical-align: top
}
#gallery-set li {
  margin-left: 10px;
  margin-bottom: 10px;
  line-height: 1
}
#gallery-set a {
  padding: 5px;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: Raleway,sans-serif;
  text-decoration: none;
  letter-spacing: 0;
  color: #b3b3b3
}
#gallery-set a.selected,
#gallery-set a:hover {
  color: #333
}
.gallery .__inner {
  margin-bottom: -30px
}
.gallery .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px
}
.gallery .__item[data-y="2"] .__image {
  height: 610px
}
.gallery .__item:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.gallery .__item:hover .__image .__content {
  visibility: visible;
  opacity: 1
}
.gallery .__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 290px;
  margin: 0
}
.gallery .__image img {
  transform: translateZ(0);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
.gallery .__image .__content {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  background-color: rgba(51,51,51,.9);
  text-decoration: none;
  color: #b3b3b3;
  visibility: hidden;
  opacity: 0
}
.gallery .__image .__content__title {
  color: #fff
}
.gallery .__image .gallery__item__subtitle {
  font-size: 1.2rem
}
.gallery .__content {
  position: relative;
  width: 100%;
  line-height: 1;
  font-size: 1.4rem;
  color: #666;
  padding-top: 2rem;
  transition: visibility .3s ease-in-out,opacity .3s ease-in-out
}
@media screen and (prefers-reduced-motion:reduce) {
  .gallery .__content,
  .gallery .__image img,
  .team .__item .__content,
  .team .__item .__image img {
    transition: none
  }
}
.gallery .__content__title {
  margin-bottom: 10px
}
.gallery--style-3 .__inner,
.gallery--style-3 .__item {
  margin-bottom: 0
}
.gallery--style-3 .__image {
  height: 0;
  padding-top: 92.5%
}
.gallery--style-3 .__image .__content {
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(36,22,16,.7)
}
#gallery-more-btn {
  margin-top: 40px
}
@media (min-width:768px) {
  #gallery-more-btn {
    margin-top: 55px
  }
  .shop-home-page .simple-banner--style-1 {
    background-image: url(../img/banner_bg_5.jpg);
    background-position: top left 50vw
  }
}
.simple-text-block {
  background-color: #fff;
  padding: 90px 15px
}
.simple-text-block--no-pt {
  padding-top: 0
}
.simple-text-block--no-pb {
  padding-bottom: 0
}
.simple-text-block .product-description {
  line-height: 1.2;
  text-transform: uppercase;
  color: #1f1f1f
}
.simple-text-block .product-description .__name,
.simple-text-block .product-description .__title {
  margin-top: 10px;
  margin-bottom: 10px
}
.simple-text-block .product-description .__name:first-child,
.simple-text-block .product-description .__title:first-child {
  margin-top: 0
}
.simple-text-block .product-description .__name:last-child,
.simple-text-block .product-description .__title:last-child {
  margin-bottom: 0
}
.simple-text-block .product-description .__title {
  font-size: 1.2rem;
  color: #b6b6b6
}
.simple-text-block .product-description .__name {
  font-size: 2.2rem;
  font-family: Raleway,sans-serif;
  font-weight: 900
}
.simple-text-block .product-description .__details__inner {
  margin-bottom: -30px
}
.simple-text-block .product-description .__details__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  line-height: 1
}
.simple-text-block .product-description .__num {
  font-size: 3.6rem;
  font-weight: 900;
  color: #aeaeab
}
.simple-text-block .slick-dots {
  position: relative;
  text-align: right
}
.simple-text-block--a {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #25292b
}
.simple-text-block--a .imgs img {
  position: absolute;
  z-index: -21
}
.simple-text-block--a .img_1 {
  top: -6%;
  left: -11.5%
}
.simple-text-block--a .img_2 {
  bottom: -20%;
  left: -19%
}
.simple-text-block--a .img_3 {
  top: -19%;
  right: -23%
}
.simple-text-block--a .img_4 {
  right: -34%;
  bottom: -33%
}
.page-404 {
  border-bottom: 1px solid #dadada
}
.page-404 .__text {
  margin-bottom: 40px;
  font-size: 1.8rem;
  color: #b3b3b3
}
@media (min-width:992px) {
  #gallery-more-btn {
    margin-top: 70px
  }
  .page-404 .__text {
    margin-bottom: 70px
  }
}
.woocommerce-page #hero {
  min-height: 420px;
  padding-top: 110px
}
.woocommerce-page .decor-el {
  position: absolute;
  display: none
}
.woocommerce-page .decor-el--1 {
  left: -20px
}
.woocommerce-page .decor-el--2 {
  left: 10%
}
.woocommerce-page .decor-el--3 {
  right: 9%
}
.woocommerce-page .decor-el--4 {
  right: 11%
}
.woocommerce-page .decor-el--5 {
  right: 2%
}
.woocommerce-page .widget--categories .list__item:not(:first-child) {
  margin-top: 15px
}
.woocommerce-page .widget--categories .list__item__link {
  font-size: 1.6rem;
  font-weight: 700;
  color: #333
}
.woocommerce-page .widget--categories .list__item__link:focus,
.woocommerce-page .widget--categories .list__item__link:hover {
  color: #aeaeab
}
.shop-home-page .decor-el--1 {
  top: 62%
}
.shop-home-page .decor-el--2 {
  top: 30%
}
.shop-home-page .decor-el--3 {
  top: 17%
}
.shop-home-page .decor-el--4 {
  top: 42%
}
.shop-home-page .decor-el--5 {
  top: 62%
}
.shop-home-page .simple-banner--style-1 {
  background-color: #f3f5f8
}
.catalog-page .decor-el--1 {
  top: 15%
}
.catalog-page .decor-el--2 {
  top: 40%
}
.catalog-page .decor-el--3 {
  top: 8%
}
.catalog-page .decor-el--4 {
  top: 28%
}
.catalog-page .decor-el--5 {
  top: 52%
}
.product-page .decor-el--1 {
  top: 15%
}
.product-page .decor-el--2 {
  top: 51%
}
.product-page .decor-el--3 {
  top: 9%
}
.product-page .decor-el--4 {
  top: 32%
}
.product-page .decor-el--5 {
  top: 52%
}
.cart-page .decor-el--1 {
  top: 20%
}
.cart-page .decor-el--2 {
  top: 70%
}
.cart-page .decor-el--3 {
  top: 14%
}
.cart-page .decor-el--4 {
  top: 46%
}
.cart-page .decor-el--5 {
  top: 69%
}
.checkout-page .decor-el--1 {
  top: 20%
}
.checkout-page .decor-el--2 {
  top: 67%
}
.checkout-page .decor-el--3 {
  top: 14%
}
.checkout-page .decor-el--4 {
  top: 40%
}
.checkout-page .decor-el--5 {
  top: 62%
}
.auth-page .decor-el--1 {
  top: 45%
}
.auth-page .decor-el--3 {
  top: 25%
}
.auth-page .decor-el--4 {
  top: 72%
}
@media (min-width:1200px) {
  .woocommerce-page .decor-el {
    display: block
  }
}
.goods .__item {
  position: relative;
  width: 100%
}
.goods .__image {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden
}
.goods .__image:before {
  content: "";
  display: block
}
.goods .__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto
}
.goods .__title {
  margin-bottom: 5px;
  font-weight: 700;
  text-transform: none
}
.goods .__category {
  line-height: 1.4;
  font-size: 1.4rem;
  color: #666
}
.goods .__category a {
  display: inline-block;
  color: inherit;
  text-decoration: none
}
.goods .rating__item {
  font-size: 1rem
}
.goods .product-price {
  margin-left: -10px;
  margin-bottom: 20px
}
.goods .product-price__item {
  margin-left: 10px
}
.goods .custom-btn {
  padding-left: 0;
  padding-right: 0
}
.goods .custom-btn i {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  font-size: 2.2rem;
  margin: -7px 10px -6px -5px
}
.goods--style-1 .__inner {
  margin-bottom: -30px
}
.goods--style-1 .__item {
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  transition: box-shadow .3s cubic-bezier(.25,.46,.45,.94)
}
.goods--style-1 .__item:hover {
  box-shadow: 0 1px 18px 0 rgba(0,0,0,.15)
}
.goods--style-1 .__image {
  max-height: 300px
}
.goods--style-1 .__image:before {
  padding-top: 88.88889%
}
.goods--style-1 .__content {
  padding: 5px 15px 25px
}
@media (min-width:576px) {
  .simple-text-block--a {
    margin-left: auto;
    margin-right: auto
  }
  .goods--style-1 .__content {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 35px
  }
}
.goods--style-1 .product-price {
  margin-top: 20px
}
.goods--style-2 .__inner {
  margin-bottom: -40px
}
.goods--style-2 .__item {
  margin-bottom: 40px
}
.goods--style-2 .__image {
  height: 100%;
  max-height: 150px
}
.goods--style-2 .__image:before {
  padding-top: 76.47059%
}
.goods--style-2 .__image img {
  transform: translateY(5%)
}
.goods--style-2 .rating {
  margin-top: 10px;
  margin-bottom: 10px
}
.goods--style-2 .product-price {
  margin-top: 10px
}
.goods--style-2 .product-label {
  top: 0;
  left: 0
}
.goods--style-3 .__inner {
  margin-bottom: -30px
}
.goods--style-3 .__item {
  margin-bottom: 30px;
  text-align: center
}
.goods--style-3 .__item:hover .__image img {
  transform: scale(1.2) translateZ(0)
}
.goods--style-3 .__image {
  max-height: 270px;
  border-radius: 10px
}
.goods--style-3 .__image:before {
  padding-top: 86.66667%
}
.goods--style-3 .__image img {
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  font-family: "object-fit: cover; object-position: 50% 50%";
  transition: transform .7s cubic-bezier(.25,.46,.45,.94),opacity .2s
}
@media screen and (prefers-reduced-motion:reduce) {
  .goods--style-1 .__item,
  .goods--style-3 .__image img {
    transition: none
  }
}
.goods--style-3 .__content {
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px
}
.goods--style-3 .rating {
  margin-top: 7px;
  margin-bottom: 13px
}
.goods--style-3 .product-price {
  margin-top: 10px
}
.goods--style-3 .product-label {
  top: 0;
  left: 0
}
.goods--slider .slick-slider {
  margin-top: -15px;
  margin-bottom: -15px
}
.goods--slider .slick-list {
  margin-left: -15px;
  margin-right: -15px
}
.goods--slider .slick-slide {
  padding-left: 15px;
  padding-right: 15px
}
.goods--slider .slick-dots {
  position: relative;
  margin-top: 35px;
  text-align: center
}
.goods--slider .__item {
  margin-top: 15px;
  margin-bottom: 15px
}
.goods-catalog .goods-filter-btn-open {
  display: block;
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 700;
  color: #25272a;
  cursor: pointer
}
.goods-catalog .goods-filter-btn-open i {
  display: inline-block;
  margin-right: 10px;
  line-height: 1.6rem;
  color: #b9b9b9
}
.goods-catalog .goods-filter-btn-open i:before {
  vertical-align: top
}
.goods-catalog .goods-filter-btn-close {
  position: absolute;
  top: 105px;
  right: 10px;
  width: 1em;
  line-height: 1;
  font-size: 1.8rem;
  color: #323232;
  text-align: center;
  z-index: 5
}
.goods-catalog .scrolled .goods-filter-btn-close {
  top: 15px
}
.goods-catalog .ordering {
  min-width: 185px
}
.goods-catalog .ordering .input-wrp {
  margin: 0
}
@media (max-width:575.98px) {
  .goods-catalog .goods-filter__inner {
    padding-left: 15px!important;
    padding-right: 15px!important
  }
}
@media (max-width:767.98px) {
  .goods-catalog .goods-filter {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 320px;
    background-color: #fff;
    backface-visibility: hidden;
    transform: translateX(-100%);
    transition: transform .4s ease-in-out
  }
  .goods-catalog .goods-filter.is-active {
    box-shadow: 0 0 35px 0 rgba(0,0,0,.05);
    transform: translateX(0)
  }
  .goods-catalog .goods-filter.scrolled .goods-filter__inner {
    border-top-width: 50px
  }
  .goods-catalog .goods-filter__inner {
    width: 100%;
    height: 100%;
    padding: 0 30px 50px;
    border-top: 140px solid transparent;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: scrollbar
  }
}
@media screen and (max-width:767.98px) and (prefers-reduced-motion:reduce) {
  .goods-catalog .goods-filter {
    transition: none
  }
}
@media (min-width:768px) {
  .goods-catalog .goods-filter-btn-close,
  .goods-catalog .goods-filter-btn-open {
    display: none
  }
}
.product-single .__product-img {
  position: relative;
  margin-bottom: 40px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  overflow: hidden
}
@media (min-width:992px) {
  .product-single .__product-img {
    margin-right: 20px;
    margin-bottom: 0
  }
}
@media (min-width:1200px) {
  .product-single .__product-img {
    margin-right: 40px
  }
}
.product-single .__product-img:before {
  content: "";
  display: block;
  padding-top: 102.32558%
}
.product-single .__product-img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto
}
.product-single .__name {
  margin-bottom: 5px;
  text-transform: none
}
.product-single .__name:first-child {
  margin-top: -.25em
}
.product-single .__categories {
  line-height: 1.5
}
.product-single .product-price {
  margin-top: 20px;
  margin-left: -10px;
  margin-bottom: 20px
}
.product-single .product-price__item {
  margin-left: 10px
}
.product-single .rating {
  margin-top: 25px
}
.product-single .__add-to-cart button[type=submit] {
  margin-top: 20px;
  white-space: nowrap
}
.product-single .__add-to-cart button[type=submit] i {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  font-size: 2.2rem;
  margin: -7px 10px -6px -5px
}
.product-single .tab-nav {
  margin-bottom: -1px
}
.product-single .tab-nav a {
  border-bottom: 4px solid transparent;
  line-height: 35px;
  font-weight: 700;
  color: #aaa
}
.product-single .tab-nav a.active {
  color: #333;
  border-color: #aeaeab
}
.product-single .tab-content {
  border-top: 1px solid #e5e5e5
}
.product-single .tab-content__item {
  padding-top: 45px
}
.product-single .description-table {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.4;
  color: #333
}
.product-single .description-table:first-child {
  margin-top: 0
}
.product-single .description-table:last-child {
  margin-bottom: 0
}
.product-single .description-table tr:nth-of-type(odd) {
  background-color: #f6f6f6
}
.product-single .description-table td {
  padding: 10px 15px;
  height: 40px
}
.product-single .description-table td:first-child {
  padding-left: 20px
}
.product-single .description-table td:last-child {
  padding-right: 20px
}
.product-single .comments-list .comment:first-child,
.product-single .comments-list:first-child {
  margin-top: 0
}
.product-single .comments-list .rating {
  margin-top: 0;
  margin-left: auto
}
.product-single .__add-review {
  margin-top: 60px
}
.product-single .__add-review .rating {
  margin-top: 30px;
  margin-bottom: 30px;
  direction: rtl;
  text-align: left
}
.product-single .__add-review .rating .__note {
  float: left;
  margin-right: 10px;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 0;
  direction: ltr
}
.product-single .__add-review button[type=submit] {
  margin-top: 0
}
.cart__table {
  font-size: 1.6rem;
  color: #333;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}
.cart__table table {
  min-width: 650px
}
.cart__table thead {
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 900;
  font-family: Raleway,sans-serif;
  text-transform: uppercase;
  color: #adadad
}
.cart__table thead tr {
  border-bottom: 2px solid #f1f1f1
}
.cart__table thead td {
  padding-bottom: 25px
}
.cart__table tbody tr {
  border-bottom: 1px solid #e6e6e6
}
.cart__table tbody td {
  padding-top: 10px;
  padding-bottom: 9px
}
.cart__table td {
  padding-left: 15px
}
.cart__table td:first-child {
  padding-left: 0
}
.cart__table .__image {
  position: relative;
  width: 120px;
  height: 120px;
  margin-right: 20px
}
@media (min-width:992px) {
  .cart__table .__image {
    margin-right: 40px
  }
}
.cart__table .__image a {
  display: block;
  width: 100%;
  height: 100%
}
.cart__table .__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 80%
}
.cart__table .__name {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4;
  margin-bottom: 5px;
  font-weight: 700;
  font-family: Raleway,sans-serif;
  text-decoration: none
}
.cart__table .__name:last-child {
  margin-bottom: 0
}
.cart__table .__name:not(:hover):not(:focus) {
  color: inherit
}
.cart__table .__price,
.cart__table .__total {
  font-weight: 700;
  white-space: nowrap
}
.cart__table .__remove {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fe5f55
}
.cart__coupon {
  width: 100%;
  max-width: 420px
}
.cart__total {
  background-color: #f9f9f9;
  min-width: 230px;
  line-height: 1.3;
  color: #555
}
.cart__total thead td {
  padding-top: 35px;
  padding-bottom: 20px
}
.cart__total tbody {
  font-size: 1.6rem;
  font-family: Raleway,sans-serif;
  text-align: right
}
.cart__total tbody tr:last-child {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb
}
.cart__total tbody td {
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 20px
}
.cart__total tbody td:first-child {
  font-weight: 700;
  text-align: left;
  color: #333
}
.cart__total tfoot td {
  padding-top: 30px;
  padding-bottom: 40px
}
.cart__total td {
  padding-left: 15px;
  padding-right: 15px
}
.cart__total td:first-child {
  padding-left: 30px
}
.cart__total td:last-child {
  padding-right: 30px
}
.cart__total .custom-btn {
  margin-top: 0
}
.checkout__table {
  font-size: 1.6rem;
  color: #333;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}
.checkout__table:first-child {
  margin-top: 0
}
.checkout__table table {
  min-width: 380px
}
.checkout__table tbody tr {
  border-bottom: 1px solid #e6e6e6
}
.checkout__table td {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px
}
.checkout__table td:first-child {
  padding-left: 0
}
@media (min-width:576px) {
  .checkout__table td {
    padding-left: 20px
  }
  .advantages .__item:before {
    content: "";
    position: absolute;
    top: 30px;
    left: -15px;
    bottom: 30px;
    border-left: 1px solid #e5e5e5
  }
}
.checkout__table .__image {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 15px
}
@media (min-width:992px) {
  .checkout__table .__image {
    margin-right: 25px
  }
}
.checkout__table .__image a {
  display: block;
  width: 100%;
  height: 100%
}
.checkout__table .__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
  max-height: 80%
}
.checkout__table .__name {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4;
  margin-bottom: 5px;
  font-family: Raleway,sans-serif;
  font-weight: 700;
  text-decoration: none
}
.checkout__table .__name:last-child {
  margin-bottom: 0
}
.checkout__table .__name:not(:hover):not(:focus) {
  color: inherit
}
.checkout__table .__amount,
.checkout__table .__total {
  font-weight: 700;
  white-space: nowrap
}
.checkout__total {
  background-color: #f9f9f9;
  margin-top: 50px;
  line-height: 1.3;
  color: #8b8b8b
}
.checkout__total thead td {
  padding-top: 35px;
  padding-bottom: 20px
}
.checkout__total tbody {
  font-size: 1.6rem;
  font-family: Raleway,sans-serif;
  text-align: right
}
.checkout__total tbody tr:last-child {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb
}
.checkout__total tbody td {
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 20px
}
.checkout__total tbody td:first-child {
  font-weight: 700;
  text-align: left;
  color: #333
}
.checkout__total tfoot {
  background-color: #e8e8e8
}
.checkout__total tfoot tr:last-child td {
  padding-top: 30px;
  padding-bottom: 40px
}
.checkout__total tfoot td {
  padding-top: 25px;
  padding-bottom: 25px
}
.checkout__total td {
  padding-left: 15px;
  padding-right: 15px
}
.checkout__total td:first-child {
  padding-left: 30px
}
.checkout__total td:last-child {
  padding-right: 30px
}
.checkout__total .__note {
  background-color: #bce5dd;
  color: #57887e
}
.checkout__total .__note .__ttl {
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Raleway,sans-serif;
  text-transform: uppercase;
  color: #3a5d56
}
.checkout__total .custom-btn {
  margin-top: 0
}
.auth-form button[type=submit] {
  min-width: 0
}
.auth-form button[type=submit]:first-child {
  margin-top: 0;
  margin-right: 20px
}
.auth-form .link-forgot {
  display: inline-block;
  margin-top: 20px;
  line-height: 1.2;
  font-size: 1.4rem;
  text-decoration: underline;
  color: #7f7345
}
.auth-form .link-forgot:focus,
.auth-form .link-forgot:hover {
  text-decoration: none
}
.auth-form .link-to {
  display: block;
  padding: 5px;
  line-height: 1.4;
  font-size: 1.2rem;
  font-family: Raleway,sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #4f4a37
}
.advantages .__inner {
  margin-bottom: -30px;
  overflow: hidden
}
.advantages .__item {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  text-align: center
}
.advantages .__ico {
  display: inline-block;
  vertical-align: top;
  line-height: 1
}
.advantages .__ico > svg {
  vertical-align: middle
}
.advantages .__title {
  margin-bottom: 5px;
  font-weight: 700;
  text-transform: none
}
.promo-banners .row {
  margin-left: -5px;
  margin-right: -5px
}
.promo-banners .row > .col,
.promo-banners .row > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px
}
.promo-banners .__item {
  display: block;
  margin: 15px 0
}